import React, { useEffect, useState } from "react";
import "./MerchantPreview.css";
import {
  ErrorHandlerType,
  ISingleMerchant,
  SetErrorHandlerType,
  SetSuccessHandlerType,
  SuccessHandlerType,
} from "../../../Types";
import { ISingleStock } from "../../requests/Requests";
import angleLeftIcon from "../../../images/angle-left.svg";
import moment from "moment";
import pinIcon from "../../../images/pin-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getAllProductsPerMerchant } from "../../../redux/actions/productActions";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import {
  holdMerchantAccount,
  unholdMerchantAccount,
} from "../../../redux/actions/merchantActions";

interface IProps {
  selectedMerchantForPreview: ISingleMerchant | null;
  setSelectedMerchantForPreview: React.Dispatch<
    React.SetStateAction<ISingleMerchant | null>
  >;
  setPageContent: React.Dispatch<React.SetStateAction<string>>;
  errorHandlerObj: ErrorHandlerType;
  successHandlerObj: SuccessHandlerType;
  setErrorHandlerObj: SetErrorHandlerType;
  setSuccessHandlerObj: SetSuccessHandlerType;
  setIsPreviewedMerchantUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

function MerchantPreview({
  selectedMerchantForPreview,
  setSelectedMerchantForPreview,
  setPageContent,
  errorHandlerObj,
  successHandlerObj,
  setErrorHandlerObj,
  setSuccessHandlerObj,
  setIsPreviewedMerchantUpdated,
}: IProps) {
  // Functions, States and Variables
  const products = useAppSelector(
    (state) => state.products.productsPerMerchant
  );
  const dispatch = useAppDispatch();
  // States
  const [allStockPerMerchant, setAllStockPerMerchant] = useState<
    ISingleStock[] | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isHoldMerchantLoading, setIsHoldMerchantLoading] = useState(false);

  // Functions
  // Hold Merchant
  const handleHoldMerchantAccount = function () {
    setIsPreviewedMerchantUpdated(false);
    if (selectedMerchantForPreview) {
      dispatch(
        holdMerchantAccount(
          selectedMerchantForPreview._id,
          setIsHoldMerchantLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setIsPreviewedMerchantUpdated
        )
      );
    }
  };

  // Unhold Merchant
  const handleUnholdMerchantAccount = function () {
    setIsPreviewedMerchantUpdated(false);
    if (selectedMerchantForPreview) {
      dispatch(
        unholdMerchantAccount(
          selectedMerchantForPreview._id,
          setIsHoldMerchantLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setIsPreviewedMerchantUpdated
        )
      );
    }
  };

  // UseEffects
  useEffect(() => {
    if (selectedMerchantForPreview) {
      // Get All Stock For Current Merchant
      dispatch(
        getAllProductsPerMerchant(
          selectedMerchantForPreview._id,
          setIsLoading,
          setErrorHandlerObj
        )
      );
    }
  }, [selectedMerchantForPreview]);

  useEffect(() => {
    console.log(products);
    setAllStockPerMerchant(products);
  }, [products]);
  return (
    <div className="employer-preview-container">
      <div className="employer-preview-container--inner">
        {/* Main Section Container */}
        <section className="employer-preview-main-section-container">
          {/* Merchant Top preview Top */}
          <div className={`merchant-preview-top-container`}>
            {/* Left Wrapper */}
            <div className="merchant-preview-top--left-wrapper">
              {/* Back button Wrapper */}
              <div className="back-button-wrapper">
                <button
                  onClick={() => {
                    setPageContent("main");
                    setSelectedMerchantForPreview(null);
                    setAllStockPerMerchant(null);
                  }}
                >
                  <img src={angleLeftIcon} alt="" />
                </button>
              </div>
              {/* Reference Number */}
              <div className="stock-preview-top-title">
                #CR
                {selectedMerchantForPreview?._id.substring(
                  selectedMerchantForPreview?._id.length - 6
                )}
              </div>
            </div>

            {/* Right Wrapper */}
            <div className="merchant-preview-top--right-wrapper">
              {/* Hold Account */}
              <div className="hold-account-button-wrapper">
                {isHoldMerchantLoading && <LoadingSpinner />}
                {!selectedMerchantForPreview?.isHeld ? (
                  <button onClick={() => handleHoldMerchantAccount()}>
                    Hold Account
                  </button>
                ) : (
                  <button onClick={() => handleUnholdMerchantAccount()}>
                    Unhold Account
                  </button>
                )}
              </div>
              {/* Delete User */}
              {/* <div className="delete-user-button-wrapper">
                <button>Delete User</button>
              </div> */}
            </div>
          </div>

          {/* Merchant Info Section */}
          <section className="merchant-preview--row-one-section">
            <div className="stock-preview--row-one-section--inner">
              {/* Shop Name and Profile Image Wrapper */}
              <div className="stock-preview--row-one--left-wrapper">
                <div className="stock-preview--merchant-img-wrapper">
                  <img
                    src={selectedMerchantForPreview?.profilePicture}
                    alt=""
                  />
                </div>

                <div className="stock-preview--merchant-shop-name">
                  {selectedMerchantForPreview?.shopName}
                </div>
              </div>

              {/* Merchant Personal Info Wrapper */}
              <div className="stock-preview--row-one--right-wrapper">
                {/* Stock Date Created */}
                <div className="stock-request-preview--date-created stock-request-preview--merchant-detail-wrapper">
                  <div className="info-detail-title">Date Created</div>
                  <div className="info-detail-value">
                    {moment(selectedMerchantForPreview?.createdAt).format(
                      "DD, MMM YY"
                    )}
                  </div>
                </div>
                {/* Merchant Email */}
                <div className="stock-request-preview--email stock-request-preview--merchant-detail-wrapper">
                  <div className="info-detail-title">Email</div>
                  <div className="info-detail-value">
                    {selectedMerchantForPreview?.email}
                  </div>
                </div>

                {/* Merchant Phone Number */}
                <div className="stock-request-preview--phone stock-request-preview--merchant-detail-wrapper">
                  <div className="info-detail-title">Phone Number</div>
                  <div className="info-detail-value">
                    {selectedMerchantForPreview?.phoneNumber}
                  </div>
                </div>

                {/* Merchant Address */}
                <div className="stock-request-preview--address ">
                  <div className="info-detail-title">Address</div>
                  <div className="info-detail-value">
                    {selectedMerchantForPreview?.address}
                  </div>
                </div>

                {/* Dummy Wrapper */}
                <div className="stock-request-preview--dummy-wrapper stock-request-preview--merchant-detail-wrapper"></div>

                {/* Merchant Address */}
                <div className="stock-request-preview--address stock-request-preview--merchant-detail-wrapper">
                  <div className="info-detail-title">Document</div>
                  <div className="info-detail-value">
                    <div className="cac-document-link--wrapper">
                      {selectedMerchantForPreview?.cacDocument ? (
                        <a
                          href={selectedMerchantForPreview.cacDocument}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={pinIcon} alt="" />
                          CAC Document.
                          {selectedMerchantForPreview.cacDocument.substring(
                            selectedMerchantForPreview.cacDocument.length - 3
                          )}
                        </a>
                      ) : (
                        <div className="no-document-link--wrapper">
                          No Document
                        </div>
                      )}
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*  Merchant's Stock Awaiting Approval */}
          <section className="merchant-preview--row-two-section">
            <div className="stock-preview--row-two-title-wrapper">Stock</div>
            {/* Boxes Container */}
            <div className="merchant-stock-boxes-container">
              {!isLoading &&
                allStockPerMerchant?.map((stock) => (
                  <div className="merchant-stock-box-wrapper">
                    {/* Top Wrapper */}
                    <div className="merchant-stock-box--top-wrapper">
                      {/* Stock Image */}
                      <div className="merchant-stock-image-wrapper">
                        <img src={stock.image} alt="" />
                      </div>
                      {/* Top Right Wrapper */}
                      <div className="merchant-stock-box--top--right-wrapper">
                        <div className="merchant-stock-name-wrapper">
                          <div className="info-detail-title">Name</div>
                          <div className="info-detail-value">{stock.name}</div>
                        </div>
                        <div className="merchant-stock-price-wrapper">
                          <div className="info-detail-title">Price</div>
                          <div className="info-detail-value">
                            &#8358;{stock.price?.toLocaleString()}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Bottom Wrapper */}
                    {/* {!isEmpty(stock.variants) && ( */}
                    <div className="merchant-stock-box--bottom-wrapper">
                      <div className="merchant-stock-box--bottom-wrapper--title-wrapper">
                        {stock.variants && stock.variants?.length < 1
                          ? "No Variants"
                          : "Variants"}
                      </div>

                      <div className="merchant-stock-variants-images-wrapper">
                        {/* Image Box Wrapper */}
                        {stock.variants?.slice(0, 3)?.map((variant) => (
                          <div className="stock-variants-image-box-wrapper">
                            <img src={variant.image} alt="" />
                          </div>
                        ))}

                        {/* Other Stock Text Wrapper */}
                        <div className="other-stock-text-wrapper">
                          {stock.variants &&
                            stock.variants?.length > 3 &&
                            "+1 other"}
                        </div>
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                ))}
            </div>

            <div className="below-table-loader-sub-wrapper">
              {isLoading && <LoadingSpinner />}
            </div>
          </section>
        </section>
      </div>
    </div>
  );
}

export default MerchantPreview;
