import React, { useEffect, useState } from "react";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAllUnapprovedCompanies } from "../../redux/actions/companyActions";
import { getAllUnapprovedMerchants } from "../../redux/actions/merchantActions";
import { getAllUnapprovedProducts } from "../../redux/actions/productActions";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import CompanyRequestsTable from "./company-requests-table/CompanyRequestsTable";
import MerchantRequestsTable from "./merchant-requests-table/MerchantRequestsTable";
import ApproveRequestsModal from "./modals/ApproveRequestsModal";
import DeclineRequestsModal from "./modals/DeclineRequestsModal";
import "./Requests.css";
import StockRequestPreview from "./stock-request-preview/StockRequestPreview";
import StockRequestsTable from "./stock-requests-table/StockRequestsTable";
import { IPartner, ISingleCompany, ISingleMerchant } from "../../Types";
import Main from "../../components/layout/main-container/Main";
import { getAllUnapprovedPartners } from "../../redux/actions/partnersAction";
import PartnerRequestsTable from "./partner-requests-table/PartnerRequestsTable";

export interface ISingleCategory {
  _id: string;
  name: string;
}

export interface IVariants {
  quantity: number;
  specification: string;
  price: number;
  image: string;
  _id: string;
}

export interface ISingleStock {
  category: ISingleCategory;
  description: string;
  image: string;
  isActive: boolean;
  isApproved: boolean;
  isRejected: boolean;
  merchant: ISingleMerchant;
  name: string;
  pictures: string[];
  price: number;
  quantity: number;
  size: string;
  _id: string;
  createdAt: string;
  variants?: IVariants[] | [];
}

function Requests() {
  // Functions, States and Variables
  const merchantRequests: ISingleMerchant[] | [] = useAppSelector(
    (state) => state.merchants.unapprovedMerchants
  );
  const stockRequests: ISingleStock[] | [] = useAppSelector(
    (state) => state.products.unapprovedProducts
  );
  const companyRequests: ISingleCompany[] | [] = useAppSelector(
    (state) => state.companies.unapprovedCompanies
  );
  const partnersRequests: IPartner[] | [] = useAppSelector(
    (state) => state.partners.unapprovedPartners
  );
  const dispatch = useAppDispatch();
  // States
  const [pageContent, setPageContent] = useState("merchant");
  const [allMerchantsRequests, setAllMerchantsRequests] = useState<
    ISingleMerchant[] | null
  >(null);
  const [allPartnersRequests, setAllPartnersRequests] = useState<
    IPartner[] | null
  >(null);
  const [allCompaniesRequests, setAllCompaniesRequests] = useState<
    ISingleCompany[] | null
  >(null);
  const [allStockRequests, setAllStockRequests] = useState<
    ISingleStock[] | null
  >(null);

  const [selectedStockRequestForPreview, setSelectedStockRequestForPreview] =
    useState<ISingleStock | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [currentMerchantRequestPosts, setCurrentMerchantRequestPosts] =
    useState<ISingleMerchant[] | null>(null);
  const [currentPartnerRequestPosts, setCurrentPartnerRequestPosts] = useState<
    IPartner[] | null
  >(null);
  const [currentCompanyRequestPosts, setCurrentCompanyRequestPosts] = useState<
    ISingleCompany[] | null
  >(null);
  const [currentStockRequestPosts, setCurrentStockRequestPosts] = useState<
    ISingleStock[] | null
  >(null);
  const [isApproveRequestModalOpened, setIsApproveRequestModalOpened] =
    useState(false);
  const [isDeclineRequestModalOpened, setIsDeclineRequestModalOpened] =
    useState(false);
  const [selectedMerchantRequest, setSelectedMerchantRequest] =
    useState<ISingleMerchant | null>(null);
  const [selectedPartnerRequest, setSelectedPartnerRequest] =
    useState<IPartner | null>(null);
  const [selectedCompanyRequest, setSelectedCompanyRequest] =
    useState<ISingleCompany | null>(null);
  const [selectedStockRequest, setSelectedStockRequest] =
    useState<ISingleStock | null>(null);
  const [isApproved, setIsApproved] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  // Functions
  // handle Change Page Content To Merchant Requests
  const handleChangePageContentToMerchantRequests = function () {
    setPageContent("merchant");

    // Update table state to merchant requests rows
    if (!allMerchantsRequests) return false;
    const currentRows = allMerchantsRequests.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    setCurrentMerchantRequestPosts(currentRows);
  };

  // handle Change Page Content To Partner Requests
  const handleChangePageContentToPartnerRequests = function () {
    setPageContent("partner");

    // Update table state to merchant requests rows
    if (!allPartnersRequests) return false;
    const currentRows = allPartnersRequests.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    setCurrentPartnerRequestPosts(currentRows);
  };

  // handle Change Page Content To Company Requests
  const handleChangePageContentToCompanyRequests = function () {
    setPageContent("company");

    // Update table state to company requests rows
    if (!allCompaniesRequests) return false;
    const currentRows = allCompaniesRequests.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    setCurrentCompanyRequestPosts(currentRows);
  };

  // handle Change Page Content To Stock Requests
  const handleChangePageContentToStockRequests = function () {
    setPageContent("stock");

    // Update table state to stock requests rows
    if (!allStockRequests) return false;
    const currentRows = allStockRequests.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    setCurrentStockRequestPosts(currentRows);
  };

  const handleFetchAppRequests = function () {
    dispatch(getAllUnapprovedProducts(setIsLoading, setErrorHandlerObj));
    dispatch(getAllUnapprovedMerchants(setIsLoading, setErrorHandlerObj));
    dispatch(getAllUnapprovedCompanies(setIsLoading, setErrorHandlerObj));
    dispatch(getAllUnapprovedPartners(setIsLoading, setErrorHandlerObj));
  };

  // Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  // UseEffects
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pageContent]);

  useEffect(() => {
    handleFetchAppRequests();
  }, []);

  useEffect(() => {
    setAllMerchantsRequests(merchantRequests);
  }, [merchantRequests]);

  useEffect(() => {
    setAllPartnersRequests(partnersRequests);
  }, [partnersRequests]);

  useEffect(() => {
    setAllStockRequests(stockRequests);
  }, [stockRequests]);

  useEffect(() => {
    setAllCompaniesRequests(companyRequests);
  }, [companyRequests]);

  // For Merchant Requests
  useEffect(() => {
    if (allMerchantsRequests) {
      const currentRows = allMerchantsRequests?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentMerchantRequestPosts(currentRows);
    }
  }, [allMerchantsRequests, currentPage]);

  // For Partner Requests
  useEffect(() => {
    if (allPartnersRequests) {
      const currentRows = allPartnersRequests?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentPartnerRequestPosts(currentRows);
    }
  }, [allPartnersRequests, currentPage]);

  // For Company Requests
  useEffect(() => {
    if (allCompaniesRequests) {
      const currentRows = allCompaniesRequests?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentCompanyRequestPosts(currentRows);
    }
  }, [allCompaniesRequests, currentPage]);

  // For Stock Requests
  useEffect(() => {
    if (allStockRequests) {
      const currentRows = allStockRequests?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentStockRequestPosts(currentRows);
    }
  }, [allStockRequests, currentPage]);

  useEffect(() => {
    // OnClose of Modal, set selected merchant or stock request to null
    if (!isApproveRequestModalOpened && !isDeclineRequestModalOpened) {
      setSelectedMerchantRequest(null);
      setSelectedStockRequest(null);
    }
  }, [isApproveRequestModalOpened, isDeclineRequestModalOpened]);

  useEffect(() => {
    if (pageContent !== "stock-preview") {
      setSelectedStockRequestForPreview(null);
      // If the current page is changed between tables, reset the current page the the default of one
      setCurrentPage(1);
    }
  }, [pageContent]);

  useEffect(() => {
    if (isApproved) handleFetchAppRequests();
  }, [isApproved]);

  useEffect(() => {
    if (isDeclined) handleFetchAppRequests();
  }, [isDeclined]);

  return (
    <div
      className={`app-page-container ${
        isApproveRequestModalOpened || isDeclineRequestModalOpened
          ? "overflow-hidden"
          : ""
      }`}
    >
      <Top />
      <Sidebar activeName={"Requests"} />

      {/* Gray Overlay */}
      <div
        onClick={() => {
          setIsApproveRequestModalOpened(false);
          setIsDeclineRequestModalOpened(false);
        }}
        className={`gray-overlay ${
          isApproveRequestModalOpened || isDeclineRequestModalOpened
            ? ""
            : "none"
        }`}
      ></div>
      {/* Confirm Approve Request For Both Stock And Merchant Dynamically */}
      <ApproveRequestsModal
        isApproveRequestModalOpened={isApproveRequestModalOpened}
        setIsApproveRequestModalOpened={setIsApproveRequestModalOpened}
        selectedMerchantRequest={selectedMerchantRequest}
        selectedPartnerRequest={selectedPartnerRequest}
        selectedCompanyRequest={selectedCompanyRequest}
        setIsApproved={setIsApproved}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
        selectedStockRequest={selectedStockRequest}
        pageContent={pageContent}
      />
      {/* Confirm Decline Request For Both Stock And Merchant Dynamically */}
      <DeclineRequestsModal
        isDeclineRequestModalOpened={isDeclineRequestModalOpened}
        setIsDeclineRequestModalOpened={setIsDeclineRequestModalOpened}
        selectedMerchantRequest={selectedMerchantRequest}
        selectedPartnerRequest={selectedPartnerRequest}
        setIsDeclined={setIsDeclined}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
        selectedStockRequest={selectedStockRequest}
        selectedCompanyRequest={selectedCompanyRequest}
        pageContent={pageContent}
      />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />
        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app--actions-handler-wrapper"
        />

        <section className={`app-main-section--inner`}>
          <div className="requests-top-wrapper">
            {/* Requests filter section */}
            <div className="requests-filter-container">
              {/* Merchant Request option */}
              <div
                className={`requests-filter-option filter-option--merchant ${
                  pageContent === "merchant" ? "active-filter-option" : ""
                }`}
                onClick={() => handleChangePageContentToMerchantRequests()}
              >
                Merchant
              </div>
              {/* Company Request option */}
              <div
                className={`requests-filter-option filter-option--stock ${
                  pageContent === "company" ? "active-filter-option" : ""
                }`}
                onClick={() => handleChangePageContentToCompanyRequests()}
              >
                Company
              </div>
              {/* Partner Request option */}
              <div
                className={`requests-filter-option filter-option--stock ${
                  pageContent === "partner" ? "active-filter-option" : ""
                }`}
                onClick={() => handleChangePageContentToPartnerRequests()}
              >
                Partner
              </div>
              {/* Stock Request option */}
              <div
                className={`requests-filter-option filter-option--stock ${
                  pageContent === "stock" || pageContent === "stock-preview"
                    ? "active-filter-option"
                    : ""
                }`}
                onClick={() => handleChangePageContentToStockRequests()}
              >
                Stock
              </div>
            </div>
          </div>

          {/* Company Requests Table Section */}
          <section
            className={`company-requests-table-section ${
              pageContent === "company" ? "" : "none"
            }`}
          >
            <CompanyRequestsTable
              companyRequests={companyRequests}
              allCompaniesRequests={allCompaniesRequests}
              currentCompanyRequestPosts={currentCompanyRequestPosts}
              setAllCompaniesRequests={setAllCompaniesRequests}
              setCurrentCompanyRequestPosts={setCurrentCompanyRequestPosts}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              indexOfFirstPost={indexOfFirstPost}
              indexOfLastPost={indexOfFirstPost}
              isLoading={isLoading}
              isApproveRequestModalOpened={isApproveRequestModalOpened}
              setIsApproveRequestModalOpened={setIsApproveRequestModalOpened}
              isDeclineRequestModalOpened={isDeclineRequestModalOpened}
              setIsDeclineRequestModalOpened={setIsDeclineRequestModalOpened}
              setSelectedCompanyRequest={setSelectedCompanyRequest}
            />
          </section>

          {/* Merchant Requests Table Section */}
          <section
            className={`merchant-requests-table-section ${
              pageContent === "merchant" ? "" : "none"
            }`}
          >
            <MerchantRequestsTable
              merchantRequests={merchantRequests}
              allMerchantsRequests={allMerchantsRequests}
              setAllMerchantsRequests={setAllMerchantsRequests}
              currentMerchantRequestPosts={currentMerchantRequestPosts}
              setCurrentMerchantRequestPosts={setCurrentMerchantRequestPosts}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              indexOfFirstPost={indexOfFirstPost}
              indexOfLastPost={indexOfLastPost}
              isLoading={isLoading}
              isApproveRequestModalOpened={isApproveRequestModalOpened}
              setIsApproveRequestModalOpened={setIsApproveRequestModalOpened}
              isDeclineRequestModalOpened={isDeclineRequestModalOpened}
              setIsDeclineRequestModalOpened={setIsDeclineRequestModalOpened}
              setSelectedMerchantRequest={setSelectedMerchantRequest}
            />
          </section>

          {/* Partner Requests Table Section */}
          <section
            className={`merchant-requests-table-section ${
              pageContent === "partner" ? "" : "none"
            }`}
          >
            <PartnerRequestsTable
              partnerRequests={partnersRequests}
              allPartnerRequests={allPartnersRequests}
              currentPartnerRequestPosts={currentPartnerRequestPosts}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              indexOfFirstPost={indexOfFirstPost}
              indexOfLastPost={indexOfLastPost}
              isLoading={isLoading}
              setCurrentPage={setCurrentPage}
              setCurrentPartnerRequestPosts={setCurrentPartnerRequestPosts}
              setAllPartnerRequests={setAllPartnersRequests}
              setIsApproveRequestModalOpened={setIsApproveRequestModalOpened}
              setIsDeclineRequestModalOpened={setIsDeclineRequestModalOpened}
              setSelectedPartnerRequest={setSelectedPartnerRequest}
            />
          </section>

          {/* Stock Requests Table Section */}
          <section
            className={`stock-requests-table-section ${
              pageContent === "stock" ? "" : "none"
            }`}
          >
            <StockRequestsTable
              stockRequests={stockRequests}
              allStockRequests={allStockRequests}
              setAllStockRequests={setAllStockRequests}
              currentStockRequestPosts={currentStockRequestPosts}
              setCurrentStockRequestPosts={setCurrentStockRequestPosts}
              setPageContent={setPageContent}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              indexOfFirstPost={indexOfFirstPost}
              indexOfLastPost={indexOfLastPost}
              isLoading={isLoading}
              isApproveRequestModalOpened={isApproveRequestModalOpened}
              setIsApproveRequestModalOpened={setIsApproveRequestModalOpened}
              isDeclineRequestModalOpened={isDeclineRequestModalOpened}
              setIsDeclineRequestModalOpened={setIsDeclineRequestModalOpened}
              setSelectedStockRequest={setSelectedStockRequest}
              setSelectedStockRequestForPreview={
                setSelectedStockRequestForPreview
              }
            />
          </section>

          {/* Stock Requests Preview Section */}
          <section
            className={`stock-request-preview-container ${
              pageContent === "stock-preview" ? "" : "none"
            }`}
          >
            <StockRequestPreview
              setPageContent={setPageContent}
              selectedStockRequestForPreview={selectedStockRequestForPreview}
              setSelectedStockRequestForPreview={
                setSelectedStockRequestForPreview
              }
              isApproveRequestModalOpened={isApproveRequestModalOpened}
              setIsApproveRequestModalOpened={setIsApproveRequestModalOpened}
              isDeclineRequestModalOpened={isDeclineRequestModalOpened}
              setIsDeclineRequestModalOpened={setIsDeclineRequestModalOpened}
              setSelectedStockRequest={setSelectedStockRequest}
              setErrorHandlerObj={setErrorHandlerObj}
              setSuccessHandlerObj={setSuccessHandlerObj}
              isApproved={isApproved}
              isDeclined={isDeclined}
            />
          </section>
        </section>
      </Main>
    </div>
  );
}

export default Requests;
