import {
  GET_ALL_PARTNERS,
  GET_ALL_PARTNERS_COUNT,
  GET_ALL_UNAPPROVED_PARTNERS,
} from "../Constants";

const initialState = {
  partners: { currentPage: 1, data: [], totalPages: 1 },
  partnerCount: 0,
  unapprovedPartners: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_ALL_PARTNERS:
      return {
        ...state,
        partners: action.payload,
      };
    case GET_ALL_PARTNERS_COUNT:
      return {
        ...state,
        partnerCount: action.payload,
      };
    case GET_ALL_UNAPPROVED_PARTNERS:
      return {
        ...state,
        unapprovedPartners: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
