import { combineReducers } from "redux";
import auditTrailReducer from "./auditTrailReducer";
import authReducer from "./authReducer";
import brandReducer from "./brandReducer";
import categoryReducer from "./categoryReducer";
import companyReducer from "./companyReducer";
import dashboardReducer from "./dashboardReducer";
import merchantReducer from "./merchantReducer";
import orderReducer from "./orderReducer";
import productReducer from "./productReducer";
import settlementsReducer from "./settlementsReducer";
import usersReducer from "./usersReducer";
import withdrawalRequestReducer from "./withdrawalRequestReducer";
import ratesReducer from "./ratesReducer";
import vouchersReducer from "./vouchersReducer";
import dealsReducer from "./dealsReducer";
import wellnessReducer from "./wellnessReducer";
import susbscriptionReducer from "./susbscriptionReducer";
import administratorsReducer from "./administratorsReducer";
import notificationsReducer from "./notificationsReducer";
import productDealsReducer from "./productDealsReducer";
import partnersReducer from "./partnersReducer";

export default combineReducers({
  user: authReducer,
  withdrawalRequest: withdrawalRequestReducer,
  merchants: merchantReducer,
  products: productReducer,
  categories: categoryReducer,
  brands: brandReducer,
  companies: companyReducer,
  orders: orderReducer,
  allUsers: usersReducer,
  settlements: settlementsReducer,
  audit: auditTrailReducer,
  dashboard: dashboardReducer,
  rates: ratesReducer,
  vouchers: vouchersReducer,
  deals: dealsReducer,
  wellness: wellnessReducer,
  subscription: susbscriptionReducer,
  administrators: administratorsReducer,
  notifications: notificationsReducer,
  productDeals: productDealsReducer,
  partners: partnersReducer,
});
