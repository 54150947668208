export const GET_ERRORS = "GET_ERRORS";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
// Withdrawal Request
export const GET_ALL_WITHDRAWAL_REQUESTS = "GET_ALL_WITHDRAWAL_REQUESTS";
// Merchants
export const GET_ALL_MERCHANTS = "GET_ALL_MERCHANTS";
export const GET_ALL_UNAPPROVED_MERCHANTS = "GET_ALL_UNAPPROVED_MERCHANTS";
// Products
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_UNAPPROVED_PRODUCTS = "GET_ALL_UNAPPROVED_PRODUCTS";
export const GET_ALL_PRODUCTS_PER_MERCHANT = "GET_ALL_PRODUCTS_PER_MERCHANT";
// Vouchers
export const GET_ALL_VOUCHERS = "GET_ALL_VOUCHERS";
export const GET_SELECTED_VOUCHERS = "GET_SELECTED_VOUCHERS";
export const GET_VOUCHER_COUNTRIES_AND_CATEGORIES =
  "GET_VOUCHER_COUNTRIES_AND_CATEGORIES";
// Categories
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
// Brands
export const GET_ALL_BRANDS = "GET_ALL_BRANDS";
// Companies
export const GET_ALL_COMPANIES = "GET_ALL_COMPANIES";
export const GET_ALL_STAFF_COUNT = "GET_ALL_STAFF_COUNT";
export const GET_ALL_UNAPPROVED_COMPANIES = "GET_ALL_UNAPPROVED_COMPANIES";
// Orders
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_ALL_ORDERS_AMOUNT = "GET_ALL_ORDERS_AMOUNT";
export const GET_ALL_ORDERS_PER_USER = "GET_ALL_ORDERS_PER_USER";
// Users
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER_ALL_LOAN_BALANCE = "GET_USER_ALL_LOAN_BALANCE";
// Settlements
export const GET_ALL_SETTLEMENTS = "GET_ALL_SETTLEMENTS";
// Audit Trail
export const GET_ALL_AUDIT_TRAIL = "GET_ALL_AUDIT_TRAIL";
// Dashboard
export const GET_TOTAL_AMOUNT_DISBURSED = "GET_TOTAL_AMOUNT_DISBURSED";
export const GET_COURSE_VOUCHER_AND_PURCHASE_COUNT =
  "GET_COURSE_VOUCHER_AND_PURCHASE_COUNT";

// RATE
export const GET_RATES = "GET_RATES";
// Deals
export const GET_ALL_DEALS = "GET_ALL_DEALS";
// Wellness Courses
export const GET_ALL_COURSES = "GET_ALL_COURSES";
export const GET_A_COURSE = "GET_A_COURSE";
export const GET_COURSE_CURRICULUM = "GET_COURSE_CURRICULUM";
export const GET_COURSE_CONTENT_FOR_COURSE = "GET_COURSE_CONTENT_FOR_COURSE";
export const GET_COURSE_ASSESSMENT_FOR_COURSE =
  "GET_COURSE_ASSESSMENT_FOR_COURSE";
export const GET_COURSE_PROGRESS = "GET_COURSE_PROGRESS";
export const GET_ALL_UNPUBLISHED_COURSES = "GET_ALL_UNPUBLISHED_COURSES";
export const GET_ALL_COURSE_CATEGORIES = "GET_ALL_COURSE_CATEGORIES";
// Company Subscriptions
export const GET_ALL_SUBSCRIPTION_PLANS = "GET_ALL_SUBSCRIPTION_PLANS";
// Administrators
export const GET_ALL_ADMINISTRATORS = "GET_ALL_ADMINISTRATORS";
// Notifications
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
// Deals
export const GET_ALL_PRODUCT_DEALS = "GET_ALL_PRODUCT_DEALS";
export const GET_ALL_PURCHASE_DEALS = "GET_ALL_PURCHASE_DEALS";
export const GET_SEARCHED_PURCHASE_DEAL = "GET_SEARCHED_PURCHASE_DEAL";
// Partners
export const GET_ALL_PARTNERS = "GET_ALL_PARTNERS";
export const GET_ALL_PARTNERS_COUNT = "GET_ALL_PARTNERS_COUNT";
export const GET_ALL_UNAPPROVED_PARTNERS = "GET_ALL_UNAPPROVED_PARTNERS";
