import React from "react";
import "./App.css";
import "./styles/Table.css";
import "./components/layout/Layout.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Requests from "./pages/requests/Requests";
import Merchants from "./pages/merchants/Merchants";
import Employers from "./pages/employers/Employers";
import Users from "./pages/users/Users";
import AuditTrail from "./pages/audit-trail/AuditTrail";
import Products from "./pages/products/Products";
import Withdrawals from "./pages/withdrawals/Withdrawals";
import SearchContextProvider from "./components/contexts/SearchContext";
import Signin from "./pages/signin/Signin";
import { Provider } from "react-redux";
import store from "./store";
import AwaitingVerification from "./components/account-verification/AwaitingVerification";
import AccountSettings from "./pages/settings/AccountSettings";
import AddNewCompany from "./components/add-new-company/AddNewCompany";
import CompanyRegistrationSuccessful from "./components/company-registration-sucessful/CompanyRegistrationSuccessful";
import AddNewMerchant from "./components/add-new-merchant/AddNewMerchant";
import MerchantRegistrationSuccessful from "./components/merchant-registration-sucessful/MerchantRegistrationSuccessful";
import Settlements from "./pages/settlements/Settlements";
import Wellness from "./pages/wellness/Wellness";
import Purchases from "./pages/purchases/Purchases";
import Deals from "./pages/deals/Deals";
import DealCreate from "./pages/deals/deal-create/DealCreate";
import DealEdit from "./pages/deals/deal-edit/DealEdit";
import CreateCourse from "./pages/wellness/create-course/CreateCourse";
import EditCourse from "./pages/wellness/edit-course/EditCourse";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import ResetPassword from "./pages/reset-password/ResetPassword";
import CourseCategory from "./pages/wellness/course-category/CourseCategory";
import Subscriptions from "./pages/subscriptions/Subscriptions";
import AddNewPlan from "./pages/subscriptions/add-new-plan/AddNewPlan";
import EditPlan from "./pages/subscriptions/edit-plan/EditPlan";
import SelectVoucherForDeal from "./pages/deals/deals-setup/select-voucher-for-deal/SelectVoucherForDeal";
import Administrators from "./pages/administrators/Administrators";
import AddNewAdmin from "./pages/administrators/add-new-admin/AddNewAdmin";
import EditAdmin from "./pages/administrators/edit-admin/EditAdmin";
import Notifications from "./pages/notifications/Notifications";
import ProductDeals from "./pages/product-deals/ProductDeals";
import ProductDealCreate from "./pages/product-deals/deal-create/ProductDealCreate";
import ProductDealEdit from "./pages/product-deals/deal-edit/ProductDealEdit";
import ProductPurchaseDeals from "./pages/product-purchase-deals/ProductPurchaseDeals";
import Partners from "./pages/partners/Partners";

function App() {
  return (
    <div className="app">
      <SearchContextProvider>
        <Provider store={store}>
          <Router>
            <Routes>
              <Route path="/" element={<Signin />} />
              <Route path="/signin" element={<Signin />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              {/* Verification Page / Account Awaiting Verification*/}
              <Route
                path="admin/verification"
                element={<AwaitingVerification />}
              />
              <Route path="/settings" element={<AccountSettings />} />

              <Route path="/admin/dashboard" element={<Dashboard />} />
              <Route path="/admin/requests" element={<Requests />} />
              <Route path="/admin/withdrawals" element={<Withdrawals />} />
              <Route path="/admin/merchants" element={<Merchants />} />
              <Route
                path="/admin/merchants/add-new"
                element={<AddNewMerchant />}
              />
              <Route
                path="/admin/merchants/account-registered"
                element={<MerchantRegistrationSuccessful />}
              />
              <Route path="/admin/employers" element={<Employers />} />
              <Route
                path="/admin/employers/add-new"
                element={<AddNewCompany />}
              />
              <Route
                path="/admin/employers/account-registered"
                element={<CompanyRegistrationSuccessful />}
              />
              <Route path="/admin/users" element={<Users />} />
              <Route path="/admin/partners" element={<Partners />} />
              <Route path="/admin/products" element={<Products />} />
              <Route path="/admin/wellness" element={<Wellness />} />
              <Route
                path="/admin/wellness/course-category"
                element={<CourseCategory />}
              />
              <Route
                path="/admin/wellness/add-new-course"
                element={<CreateCourse />}
              />
              <Route
                path="/admin/wellness/edit-course"
                element={<EditCourse />}
              />
              {/* <Route path="/admin/purchases" element={<PurchasesAndOrders />} /> */}
              <Route path="/admin/purchases" element={<Purchases />} />
              <Route path="/admin/settlements" element={<Settlements />} />
              <Route path="/admin/audit-trail" element={<AuditTrail />} />
              {/* Voucher Deals */}
              <Route path="/admin/deals" element={<Deals />} />
              <Route
                path="/admin/deals/create/select-voucher"
                element={<SelectVoucherForDeal />}
              />
              <Route path="/admin/deals/create" element={<DealCreate />} />
              <Route path="/admin/deals/edit" element={<DealEdit />} />
              {/* Product Deals */}
              <Route path="/admin/product-deals" element={<ProductDeals />} />
              <Route
                path="/admin/product-deals/create"
                element={<ProductDealCreate />}
              />
              <Route
                path="/admin/product-deals/edit"
                element={<ProductDealEdit />}
              />
              {/* Purchase Deals */}
              <Route
                path="/admin/purchase-deals"
                element={<ProductPurchaseDeals />}
              />

              {/* Subscriptions */}
              <Route path="/admin/subscriptions" element={<Subscriptions />} />
              <Route
                path="/admin/subscriptions/add-new"
                element={<AddNewPlan />}
              />
              <Route path="/admin/subscriptions/edit" element={<EditPlan />} />
              {/* Administrators */}
              <Route
                path="/admin/administrators"
                element={<Administrators />}
              />
              <Route
                path="/admin/administrators/add-new"
                element={<AddNewAdmin />}
              />
              <Route
                path="/admin/administrators/edit"
                element={<EditAdmin />}
              />
              <Route path="/admin/notifications" element={<Notifications />} />
            </Routes>
          </Router>
        </Provider>
      </SearchContextProvider>
    </div>
  );
}

export default App;
