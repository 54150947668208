import React from "react";
import { Table } from "react-bootstrap";
import moment from "moment";
import { IPartner } from "../../../../Types";
import { handleGetFileExtension } from "../../../../utils/handleGetFileExtension";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import pinIcon from "../../../../images/pin-icon.svg";

interface IProps {
  currentPosts: IPartner[] | null;
  isLoading: boolean;
}

const PartnersTable = function ({ currentPosts, isLoading }: IProps) {
  // Functions and States
  return (
    <>
      {/* Audit Trail Table Container */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "PARTNER NAME",
                "EMAIL",
                "PHONE NUMBER",
                "CAC DOCUMENT",
                "UTILITY IMAGE",
                // "NATURE OF BUSINESS",
                "DATE ADDED",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentPosts?.map((partner, i) => (
              <tr key={i + 1}>
                {/* Parner Name Column */}
                <td className="td-name">
                  <div className="td-name--wrapper">{partner.name}</div>
                </td>

                {/*  Partner Email Column */}
                <td className="td-email">
                  <div className="td-email--wrapper">{partner.email}</div>
                </td>

                {/*  Partner Phone number Column */}
                <td className="td-phone-number">
                  <div className="td-phone-number--wrapper">
                    {partner.phoneNumber}
                  </div>
                </td>

                {/*  Cac Document Column */}
                <td className="td-cac-document">
                  <div className="td-cac-document--wrapper">
                    {partner.cacDocument ? (
                      <a
                        href={partner.cacDocument}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={pinIcon} alt="" />
                        CAC Document.
                        {handleGetFileExtension(partner.cacDocument)}
                      </a>
                    ) : (
                      <div className="no-document-wrapper">No Document</div>
                    )}
                  </div>
                </td>

                {/*  Utility Image Column */}
                <td className="td-cac-document">
                  <div className="td-cac-document--wrapper">
                    {partner.utilityImage ? (
                      <a
                        href={partner.utilityImage}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={pinIcon} alt="" />
                        Utility Image.
                        {handleGetFileExtension(partner.utilityImage)}
                      </a>
                    ) : (
                      <div className="no-document-wrapper">
                        No Utility Image
                      </div>
                    )}
                  </div>
                </td>

                {/*  Partner Nature of Business Column */}
                {/* <td className="td-phone-number">
                  <div className="td-phone-number--wrapper">
                    {partner?.natureOfBusiness || "---"}
                  </div>
                </td> */}

                {/*  Date Added Column */}
                <td className="td-date-added">
                  <div className="td-date-added--wrapper">
                    {moment(partner.createdAt).format("Do MMM, YYYY")}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </>
  );
};

export default PartnersTable;
