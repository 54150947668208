import React from "react";
import "./Dashboard.css";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import ErrorHandler from "../../components/error/ErrorHandler";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import DashboardRowOne from "./dashboard-row-one/DashboardRowOne";
// import DashboardRowThree from "./dashboard-row-three/DashboardRowThree";
// import DashboardRowTwo from "./dashboard-row-two/DashboardRowTwo";
import Main from "../../components/layout/main-container/Main";

function Dashboard() {
  // Functions, states and variables
  // Hooks
  // Handles Display the error and success message and scrolls to top
  const { errorHandlerObj, setErrorHandlerObj } = useDisplayMessage();

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Dashboard"} />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />

        <section className={`app-main-section--inner`}>
          {/* ROW ONE SECTION */}
          <section className="dashboard-row-section dashboard-row--one-section">
            <DashboardRowOne setErrorHandlerObj={setErrorHandlerObj} />
          </section>

          {/* ROW TWO SECTION */}
          <section className="dashboard-row-section dashboard-row--two-section">
            {/* <DashboardRowTwo /> */}
          </section>

          {/* ROW THREE SECTION */}
          <section className="dashboard-row-section dashboard-row--three-section">
            {/* <DashboardRowThree setErrorHandlerObj={setErrorHandlerObj} /> */}
          </section>
        </section>
      </Main>
    </div>
  );
}

export default Dashboard;
