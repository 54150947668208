import React, { useContext, useEffect, useState } from "react";
import "./Merchants.css";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import { SearchContext } from "../../components/contexts/SearchContext";
import { useAppDispatch, useAppSelector } from "../../hooks";
import isEmpty from "../../validation/isEmpty";
import { getAllMerchants } from "../../redux/actions/merchantActions";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import DropdownInput from "../../components/input-components/dropdown-input/DropdownInput";
import MerchantsTable from "./merchants-table/MerchantsTable";
import MerchantPreview from "./merchant-preview/MerchantPreview";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import Main from "../../components/layout/main-container/Main";
import { ISingleMerchant } from "../../Types";

// Interfaces
// interface ISingleMerchant {}

function Merchants() {
  // Functions, States and Variables
  const merchants: ISingleMerchant[] | [] = useAppSelector(
    (state) => state.merchants.merchants
  );
  const dispatch = useAppDispatch();
  // States
  const { searchedValue }: any = useContext(SearchContext);
  const [pageContent, setPageContent] = useState("main");
  const [allMerchants, setAllMerchants] = useState<ISingleMerchant[] | null>(
    null
  );
  const [merchantsDateFilter, setMerchantsDateFilter] = useState("");
  const [selectedMerchantForPreview, setSelectedMerchantForPreview] =
    useState<ISingleMerchant | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [currentMerchantsPosts, setCurrentMerchantsPosts] = useState<
    ISingleMerchant[] | null
  >(null);
  const [isPreviewedMerchantUpdated, setIsPreviewedMerchantUpdated] =
    useState(false);

  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const [isLoading, setIsLoading] = useState(false);

  //   handle Filter Table
  const handleFilterTable = function () {
    if (!merchants || isEmpty(merchants)) return false;
    const allMerchantsData = merchants?.filter((merchant) =>
      merchant.shopName.toLowerCase().includes(searchedValue.toLowerCase())
    );

    setAllMerchants(allMerchantsData);
    // Update table state to merchant rows
    if (!allMerchants || !allMerchantsData) return false;
    const currentRows = allMerchantsData.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    setCurrentMerchantsPosts(currentRows);
  };

  // Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  // UseEffects
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pageContent]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess)
      dispatch(getAllMerchants(setIsLoading, setErrorHandlerObj));
  }, [successHandlerObj]);

  useEffect(() => {
    dispatch(getAllMerchants(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    setAllMerchants(merchants);

    // If previewed merchant is updated,
    if (isPreviewedMerchantUpdated) {
      const currentPreviewedCompany = merchants?.filter(
        (merchant: ISingleMerchant) =>
          merchant._id === selectedMerchantForPreview?._id
      );
      // Update selected merchant for preview
      if (currentPreviewedCompany)
        setSelectedMerchantForPreview(currentPreviewedCompany[0]);
    }
  }, [merchants]);

  // For Employers
  useEffect(() => {
    if (allMerchants) {
      const currentRows = allMerchants?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentMerchantsPosts(currentRows);
    }
  }, [allMerchants, currentPage]);

  useEffect(() => {
    handleFilterTable();
    setCurrentPage(1);
  }, [searchedValue]);

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Merchants"} />
      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />
        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app--actions-handler-wrapper"
        />

        {/* Main Merchants Table Section  */}
        <section
          className={`app-main-section--inner ${
            pageContent === "main" ? "" : "none"
          }`}
        >
          {/* Top  Container*/}
          <div className="merchants--top-container">
            {/* Top Companies Box Container */}
            <div className="merchants--top--box-container top-companies-box-container">
              {/* Box Title Wrapper */}
              <div className="box-title-wrapper">TOP MERCHANTS</div>
              {/* Box Value Wrapper */}
              <div className="box-value-wrapper">{merchants?.length || 0}</div>
            </div>
          </div>

          <section className={`employers-table-section`}>
            {/* Employers Table Top Wrapper */}
            <div className="employers-table--top-wrapper">
              {/* Employers Table Filter Wrapper */}
              <div className="employers-table-filter-option-wrapper requests--table-filter-option-wrapper">
                <div className="employers-table-filter-row-title requests--table-filter-row-title">
                  Filter:
                </div>
                {/* Filter by date added dropdown */}
                <div className="employers-table--date-filter-form-group form-group">
                  <DropdownInput
                    className={"employers-date-filter-dropdown-input"}
                    id={"employers-date-filter-input"}
                    label={""}
                    required={true}
                    optionArray={[
                      { key: "", value: "Date", default: "Default" },
                      {
                        key: "yesterday",
                        value: "Yesterday",
                      },
                    ]}
                    value={merchantsDateFilter}
                    onChange={(e) => setMerchantsDateFilter(e.target.value)}
                  />
                </div>
              </div>

              {/* Add New Employer Button Wrapper  */}
              <div className="add-new-button-wrapper">
                <button
                  onClick={() => {
                    // navigate("/admin/merchants/add-new");
                    window.open(
                      `${process.env.REACT_APP_MERCHANT_URL}/merchant/signup`,
                      "_blank"
                    );
                  }}
                >
                  Add New
                </button>
              </div>
            </div>

            {/* Employers Table Container */}
            <section className={`employers-table-wrapper`}>
              <MerchantsTable
                allMerchants={allMerchants}
                currentMerchantsPosts={currentMerchantsPosts}
                setPageContent={setPageContent}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                rowsPerPage={rowsPerPage}
                isLoading={isLoading}
                setSelectedMerchantForPreview={setSelectedMerchantForPreview}
              />
            </section>
          </section>
        </section>

        {/* Merchants Preview Section */}
        <section
          className={`merchants-container-main-section ${
            pageContent === "preview" ? "" : "none"
          }`}
        >
          <MerchantPreview
            setPageContent={setPageContent}
            selectedMerchantForPreview={selectedMerchantForPreview}
            setSelectedMerchantForPreview={setSelectedMerchantForPreview}
            errorHandlerObj={errorHandlerObj}
            successHandlerObj={successHandlerObj}
            setErrorHandlerObj={setErrorHandlerObj}
            setSuccessHandlerObj={setSuccessHandlerObj}
            setIsPreviewedMerchantUpdated={setIsPreviewedMerchantUpdated}
          />
        </section>
      </Main>
    </div>
  );
}

export default Merchants;
