import React, { useContext, useEffect, useState } from "react";
import "./PartnerRequestsTable.css";
import { Table } from "react-bootstrap";
import DropdownInput from "../../../components/input-components/dropdown-input/DropdownInput";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";
import isEmpty from "../../../validation/isEmpty";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import closeIcon from "../../../images/close-icon.svg";
import tickIcon from "../../../images/tick-icon.svg";
import { SearchContext } from "../../../components/contexts/SearchContext";
import pinIcon from "../../../images/pin-icon.svg";
import { IPartner } from "../../../Types";
import { handleGetFileExtension } from "../../../utils/handleGetFileExtension";

interface IProps {
  partnerRequests: [] | IPartner[];
  allPartnerRequests: IPartner[] | null;
  currentPartnerRequestPosts: IPartner[] | null;
  currentPage: number;
  rowsPerPage: number;
  indexOfFirstPost: number;
  indexOfLastPost: number;
  isLoading: boolean;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setCurrentPartnerRequestPosts: (
    value: React.SetStateAction<IPartner[] | null>
  ) => void;
  setAllPartnerRequests: React.Dispatch<
    React.SetStateAction<IPartner[] | null>
  >;
  setIsApproveRequestModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeclineRequestModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPartnerRequest: (
    value: React.SetStateAction<IPartner | null>
  ) => void;
}

const PartnerRequestsTable: React.FC<IProps> = function ({
  allPartnerRequests,
  currentPartnerRequestPosts,
  setCurrentPage,
  setCurrentPartnerRequestPosts,
  setAllPartnerRequests,
  partnerRequests,
  currentPage,
  rowsPerPage,
  indexOfFirstPost,
  indexOfLastPost,
  isLoading,
  setIsApproveRequestModalOpened,
  setIsDeclineRequestModalOpened,
  setSelectedPartnerRequest,
}) {
  // Functions and States
  const { searchedValue }: any = useContext(SearchContext);
  // States
  const [PartnerRequestsDateFilter, setPartnerRequestsDateFilter] =
    useState("");
  // Functions
  const paginateForPartnerRequests = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   handle Filter Table
  const handleFilterTable = function () {
    if (!partnerRequests || isEmpty(partnerRequests)) return false;

    const PartnerRequestsData = partnerRequests?.filter(
      (request) =>
        request.name.toLowerCase().includes(searchedValue.toLowerCase()) ||
        request.email.toLowerCase().includes(searchedValue.toLowerCase())
    );

    setAllPartnerRequests(PartnerRequestsData);
    // Update table state to partner requests rows
    if (!allPartnerRequests || !PartnerRequestsData) return false;
    const currentRows = PartnerRequestsData.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    setCurrentPartnerRequestPosts(currentRows);
  };

  useEffect(() => {
    handleFilterTable();
    setCurrentPage(1);
  }, [searchedValue]);

  return (
    <div className="merchant-requests-table-section--inner">
      {/* Partner Requests Table Filter Wrapper */}
      <div className="requests--table-filter-option-wrapper">
        <div className="requests--table-filter-row-title">Filter:</div>
        {/* Filter by partners' request application date dropdown */}
        <div className="requests--date-filter-form-group form-group">
          <DropdownInput
            className={"salary-advance-date-filter-dropdown-input"}
            id={"salary-advance-date-filter-input"}
            label={""}
            required={true}
            optionArray={[
              { key: "", value: "Date", default: "Default" },
              {
                key: "yesterday",
                value: "Yesterday",
              },
            ]}
            value={PartnerRequestsDateFilter}
            onChange={(e) => setPartnerRequestsDateFilter(e.target.value)}
          />
        </div>
      </div>

      {/* All Partner Requests Table Container */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "PARTNER NAME",
                "EMAIL",
                "PHONE NUMBER",
                "CAC DOCUMENT",
                "UTILITY IMAGE",
                "",
                "REASON",
                "",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentPartnerRequestPosts?.map((partner, i) => (
              <tr key={i + 1}>
                {/* Parner Name Column */}
                <td className="td-name">
                  <div className="td-name--wrapper">{partner.name}</div>
                </td>

                {/*  Partner Email Column */}
                <td className="td-email">
                  <div className="td-email--wrapper">{partner.email}</div>
                </td>

                {/*  Partner Phone number Column */}
                <td className="td-phone-number">
                  <div className="td-phone-number--wrapper">
                    {partner.phoneNumber}
                  </div>
                </td>

                {/*  Cac Document Column */}
                <td className="td-cac-document">
                  <div className="td-cac-document--wrapper">
                    {partner.cacDocument ? (
                      <a
                        href={partner.cacDocument}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={pinIcon} alt="" />
                        CAC Document.
                        {handleGetFileExtension(partner.cacDocument)}
                      </a>
                    ) : (
                      <div className="no-document-wrapper">No Document</div>
                    )}
                  </div>
                </td>

                {/*  Utility Image Column */}
                <td className="td-cac-document">
                  <div className="td-cac-document--wrapper">
                    {partner.utilityImage ? (
                      <a
                        href={partner.utilityImage}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={pinIcon} alt="" />
                        Utility Image.
                        {handleGetFileExtension(partner.utilityImage)}
                      </a>
                    ) : (
                      <div className="no-document-wrapper">
                        No Utility Image
                      </div>
                    )}
                  </div>
                </td>

                {/* Decline Request Btn Column*/}
                <td className="td-decline-request">
                  {!partner.isRejected ? (
                    <div className="td-decline-request--wrapper">
                      <button
                        onClick={() => {
                          setIsDeclineRequestModalOpened(true);
                          setSelectedPartnerRequest(partner);
                        }}
                      >
                        <img src={closeIcon} alt="" />
                        Decline
                      </button>
                    </div>
                  ) : (
                    <div className="item-rejected-or-declined-wrapper">
                      Declined
                    </div>
                  )}
                </td>

                {/* Decline Request Btn Column*/}
                <td className="td-decline-request">
                  {!partner?.rejectReason ? (
                    <div className="item-rejected-or-declined-wrapper"></div>
                  ) : (
                    <div className="item-rejected-or-declined-wrapper">
                      {partner.rejectReason}
                    </div>
                  )}
                </td>

                {/* Approve Request Btn Column*/}
                <td className="td-approve-request">
                  <div className="td-approve-request--wrapper">
                    <button
                      onClick={() => {
                        setIsApproveRequestModalOpened(true);
                        setSelectedPartnerRequest(partner);
                      }}
                    >
                      <img src={tickIcon} alt="" />
                      Approve
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allPartnerRequests && !isEmpty(currentPartnerRequestPosts) && (
        <FEPagination
          paginate={paginateForPartnerRequests}
          rowsPerPage={rowsPerPage}
          totalPosts={allPartnerRequests.length}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default PartnerRequestsTable;
