import axios from "axios";
import {
  GET_ALL_PARTNERS,
  GET_ALL_PARTNERS_COUNT,
  GET_ALL_UNAPPROVED_PARTNERS,
} from "../Constants";
import { api } from "../../api/config";
import {
  IPartner,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

// interfaces
const url = `${api}/api/v1/admin`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get all partners
export const getAllPartners =
  (
    pageNumberIndex: number = 1,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${api}/api/v1/partners?page=${pageNumberIndex}`)
      .then((res) => {
        dispatch({ type: GET_ALL_PARTNERS, payload: res.data });
      })
      .catch((err) => {
        handleAPIError(err, dispatch, setErrorHandlerObj);
      })
      .finally(() => setIsLoading(false));
  };

// Get all Partner count
export const getAllPartnerCount =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${api}/api/v1/partners/count`)
      .then((res) => {
        dispatch({ type: GET_ALL_PARTNERS_COUNT, payload: res.data.data });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      });
  };

// Get all unapproved Partners
export const getAllUnapprovedPartners =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${api}/api/v1/partners/unapproved`)
      .then((res) => {
        dispatch({
          type: GET_ALL_UNAPPROVED_PARTNERS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        handleAPIError(err, dispatch, setErrorHandlerObj);
      })
      .finally(() => setIsLoading(false));
  };

//   Approve Partner Request
export const approvePartnerRequest =
  (
    companyId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsApproved: React.Dispatch<React.SetStateAction<boolean>>,
    setIsApproveRequestModalOpened: React.Dispatch<
      React.SetStateAction<boolean>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/partners/approve/${companyId}`)
      .then((res) => {
        // // console.log(res.data);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Partner approved successfully!",
        });
        setIsApproved(true);
        // Close confirmation Modal
        setIsApproveRequestModalOpened(false);
      })
      .catch((err) => {
        // Close confirmation Modal
        setIsApproveRequestModalOpened(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      })
      .finally(() => setIsLoading(false));
  };

//   Decline Partner Request
export const declinePartnerRequest =
  (
    data: {
      reason: string;
    },
    companyId: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType,
    setSuccessHandlerObj: SetSuccessHandlerType,
    setIsDeclined: React.Dispatch<React.SetStateAction<boolean>>,
    setIsDeclineRequestModalOpened: React.Dispatch<
      React.SetStateAction<boolean>
    >
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .patch(`${url}/partners/reject/${companyId}`, data)
      .then((res) => {
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Partner declined successfully!",
        });
        setIsDeclined(true);
        // Close confirmation modal
        setIsDeclineRequestModalOpened(false);
      })
      .catch((err) => {
        // Close confirmation modal
        setIsDeclineRequestModalOpened(false);
        handleAPIError(err, dispatch, setErrorHandlerObj);
      })
      .finally(() => setIsLoading(false));
  };
