import React, { MouseEvent, useEffect, useState } from "react";
import SubstituteLoadingSpinner from "../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import { useAppDispatch } from "../../../hooks";
import { declineCompanyRequest } from "../../../redux/actions/companyActions";
import { declineMerchantRequest } from "../../../redux/actions/merchantRequestActions";
import { unapproveProduct } from "../../../redux/actions/productActions";
import { ISingleStock } from "../Requests";
import {
  IPartner,
  ISingleCompany,
  ISingleMerchant,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../Types";
import { declinePartnerRequest } from "../../../redux/actions/partnersAction";

interface IProps {
  setSuccessHandlerObj: SetSuccessHandlerType;
  setErrorHandlerObj: SetErrorHandlerType;
  setIsDeclineRequestModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isDeclineRequestModalOpened: boolean;
  selectedMerchantRequest: ISingleMerchant | null;
  selectedPartnerRequest: IPartner | null;
  selectedCompanyRequest: ISingleCompany | null;
  selectedStockRequest: ISingleStock | null;
  setIsDeclined: React.Dispatch<React.SetStateAction<boolean>>;
  pageContent: string;
}

function DeclineRequestsModal({
  selectedMerchantRequest,
  selectedPartnerRequest,
  selectedCompanyRequest,
  selectedStockRequest,
  setIsDeclineRequestModalOpened,
  isDeclineRequestModalOpened,
  setErrorHandlerObj,
  setSuccessHandlerObj,
  setIsDeclined,
  pageContent,
}: IProps) {
  // Functions and states
  const dispatch = useAppDispatch();
  // States
  const [reasonOfDeclination, setReasonOfDeclination] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //   Functions;
  const handleConfirmDeclineRequest = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    setIsDeclined(false);
    setSuccessHandlerObj({ isSuccess: false, message: "" });
    setErrorHandlerObj({ hasError: false, message: "" });
    if (reasonOfDeclination !== "") {
      e.preventDefault();

      const data = {
        reason: reasonOfDeclination,
      };
      // Check if request is stock or merchant
      if (pageContent === "merchant") {
        //  Call the API to Decline merchant's store requests
        if (selectedMerchantRequest) {
          //  Call the API to Approve merchant's store requests
          dispatch(
            declineMerchantRequest(
              data,
              selectedMerchantRequest._id,
              setIsLoading,
              setErrorHandlerObj,
              setSuccessHandlerObj,
              setIsDeclined,
              setIsDeclineRequestModalOpened
            )
          );
        }
      } else if (pageContent === "company") {
        //  Call the API to Decline company requests
        if (selectedCompanyRequest) {
          //  Call the API to Decline company requests
          dispatch(
            declineCompanyRequest(
              data,
              selectedCompanyRequest._id,
              setIsLoading,
              setErrorHandlerObj,
              setSuccessHandlerObj,
              setIsDeclined,
              setIsDeclineRequestModalOpened
            )
          );
        }
      } else if (pageContent === "partner") {
        //  Call the API to Decline partner requests
        if (selectedPartnerRequest) {
          //  Call the API to Decline partner requests
          dispatch(
            declinePartnerRequest(
              data,
              selectedPartnerRequest._id,
              setIsLoading,
              setErrorHandlerObj,
              setSuccessHandlerObj,
              setIsDeclined,
              setIsDeclineRequestModalOpened
            )
          );
        }
      } else {
        // TODO Call the API to Decline Stock requests
        if (selectedStockRequest) {
          dispatch(
            unapproveProduct(
              data,
              selectedStockRequest._id,
              setIsLoading,
              setErrorHandlerObj,
              setSuccessHandlerObj,
              setIsDeclined,
              setIsDeclineRequestModalOpened
            )
          );
        }
      }
    }
  };

  useEffect(() => {
    if (!isDeclineRequestModalOpened) setReasonOfDeclination("");
  }, [isDeclineRequestModalOpened]);

  return (
    <div
      className={`decline-requests-modal-container ${
        isDeclineRequestModalOpened ? "" : "none"
      }`}
    >
      <div className="decline-requests-modal-container--inner">
        <div className="decline-requests-modal-header">
          Decline Request {isLoading && <SubstituteLoadingSpinner />}
        </div>

        {/* Group name form wrapper */}
        <div className="reason-for-declination-form-wrapper">
          <form>
            {/* Reason for declination form group */}
            <div className="reason-for-declination-form-group form-group">
              <label htmlFor="reason-for-declination-textarea-input">
                Kindly input a comment as to why you are declining this{" "}
                {pageContent === "merchant"
                  ? "merchant"
                  : pageContent === "company"
                  ? "company"
                  : pageContent === "partner"
                  ? "partner"
                  : "stock's"}{" "}
                <span>
                  {pageContent === "merchant"
                    ? selectedMerchantRequest?.shopName + "'s"
                    : pageContent === "company"
                    ? selectedCompanyRequest?.companyName
                    : pageContent === "partner"
                    ? selectedPartnerRequest?.name + "'s"
                    : ""}
                </span>{" "}
                request
              </label>
              <textarea
                id={"reason-for-declination-textarea-input"}
                className={"reason-for-declination-input"}
                placeholder={""}
                value={reasonOfDeclination}
                onChange={(e) => setReasonOfDeclination(e.target.value)}
                required={true}
              />
            </div>

            <div className="decline-requests-modal-action-buttons-wrapper">
              {/* Submit Create Group Btn Wrapper */}
              <div className="submit-decline-requests-btn-wrapper">
                <button onClick={(e) => handleConfirmDeclineRequest(e)}>
                  Done
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default DeclineRequestsModal;
