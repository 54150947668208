import React, { MouseEvent, useState } from "react";
import "./Modals.css";
import attentionIcon from "../../../images/attention-icon.svg";
import { ISingleStock } from "../Requests";
import { useAppDispatch } from "../../../hooks";
import { approveMerchantRequest } from "../../../redux/actions/merchantRequestActions";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { approveProduct } from "../../../redux/actions/productActions";
import { approveCompanyRequest } from "../../../redux/actions/companyActions";
import {
  IPartner,
  ISingleCompany,
  ISingleMerchant,
  SetErrorHandlerType,
  SetSuccessHandlerType,
} from "../../../Types";
import { approvePartnerRequest } from "../../../redux/actions/partnersAction";

interface IProps {
  setSuccessHandlerObj: SetSuccessHandlerType;
  setErrorHandlerObj: SetErrorHandlerType;
  setIsApproveRequestModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isApproveRequestModalOpened: boolean;
  selectedMerchantRequest: ISingleMerchant | null;
  selectedPartnerRequest: IPartner | null;
  selectedCompanyRequest: ISingleCompany | null;
  selectedStockRequest: ISingleStock | null;
  setIsApproved: React.Dispatch<React.SetStateAction<boolean>>;
  pageContent: string;
}

const ApproveRequestsModal: React.FC<IProps> = function ({
  selectedMerchantRequest,
  selectedPartnerRequest,
  selectedCompanyRequest,
  selectedStockRequest,
  setIsApproveRequestModalOpened,
  isApproveRequestModalOpened,
  setErrorHandlerObj,
  setSuccessHandlerObj,
  setIsApproved,
  pageContent,
}) {
  // Functions, States and variables
  const dispatch = useAppDispatch();
  // STates
  const [isLoading, setIsLoading] = useState(false);
  // Functions
  const handleConfirmApproveRequest = function (
    e: MouseEvent<HTMLButtonElement>
  ) {
    setIsApproved(false);
    setSuccessHandlerObj({ isSuccess: false, message: "" });
    setErrorHandlerObj({ hasError: false, message: "" });
    e.preventDefault();
    // Check if request is stock or merchant
    if (pageContent === "merchant") {
      if (selectedMerchantRequest) {
        //  Call the API to Approve merchant's store requests
        dispatch(
          approveMerchantRequest(
            selectedMerchantRequest._id,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            setIsApproved,
            setIsApproveRequestModalOpened
          )
        );
      }
    } else if (pageContent === "company") {
      if (selectedCompanyRequest) {
        //  Call the API to Approve Company's store requests
        dispatch(
          approveCompanyRequest(
            selectedCompanyRequest._id,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            setIsApproved,
            setIsApproveRequestModalOpened
          )
        );
      }
    } else if (pageContent === "partner") {
      if (selectedPartnerRequest) {
        //  Call the API to Approve Partner's store requests
        dispatch(
          approvePartnerRequest(
            selectedPartnerRequest._id,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            setIsApproved,
            setIsApproveRequestModalOpened
          )
        );
      }
    } else {
      //  Call the API to Approve Stock requests
      if (selectedStockRequest) {
        dispatch(
          approveProduct(
            selectedStockRequest._id,
            setIsLoading,
            setErrorHandlerObj,
            setSuccessHandlerObj,
            setIsApproved,
            setIsApproveRequestModalOpened
          )
        );
      }
    }
  };
  return (
    <div
      className={`approve-requests-modal-container modal-container ${
        isApproveRequestModalOpened ? "" : "none"
      }`}
    >
      <div className="approve-requests-modal-container--inner modal-container--inner">
        <div className="attention-img-wrapper">
          <img src={attentionIcon} alt="" />
        </div>

        <div className="approve-requests-modal-header-text modal-header-text">
          Kindly confirm this action
        </div>
        <div className="approve-requests-modal-sub-text modal-sub-text">
          Would you like to approve the request of this{" "}
          {pageContent === "merchant"
            ? "merchant's store"
            : pageContent === "company"
            ? "company"
            : pageContent === "partner"
            ? "partner"
            : "stock"}
          ?{" "}
          <span>
            {pageContent === "merchant"
              ? selectedMerchantRequest?.shopName
              : pageContent === "company"
              ? selectedCompanyRequest?.companyName
              : selectedStockRequest?.name}
          </span>
        </div>

        <div className="modal-action-button-wrapper">
          {/* Cancel Modal Button */}
          <div className="cancel-modal-button-wrapper">
            <button onClick={() => setIsApproveRequestModalOpened(false)}>
              Cancel
            </button>
          </div>
          {/* Conffirm Action Button */}
          <div className="confirm-modal-action-button">
            <button onClick={(e) => handleConfirmApproveRequest(e)}>
              Confirm
            </button>
            <div className="confirm-modal-button-loader-wrapper">
              {isLoading && <LoadingSpinner />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveRequestsModal;
