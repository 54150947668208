export const adminRolesOptions = [
  { key: "superAdmin", value: "Super Admin" },
  { key: "finance", value: "Finance" },
  { key: "operations", value: "Operations" },
  { key: "author", value: "Author" },
];

export const adminRolesAccessArr = [
  {
    roleKey: "superAdmin",
    access: [
      "Dashboard",
      "Requests",
      "Withdrawals",
      "Merchants",
      "Employers",
      "Users",
      "Partners",
      "Products",
      "Learning",
      "Purchases",
      "Voucher Deals",
      "Settlements",
      "Administrators",
      "Subscriptions",
      "Notifications",
      "Audit Trail",
      "Product Deals",
    ],
  },
  { roleKey: "finance", access: ["Dashboard", "Withdrawals", "Settlements"] },
  {
    roleKey: "operations",
    access: [
      "Dashboard",
      "Requests",
      "Merchants",
      "Employers",
      "Users",
      "Partners",
      "Products",
      "Purchases",
      "Product Deals",
      "Voucher Deals",
      "Settlements",
      "Notifications",
    ],
  },
  { roleKey: "author", access: ["Dashboard", "Learning"] },
];

export const handleGetRoleNameFromKey = function (roleKey: string) {
  const currentRoleObject = adminRolesOptions.find(
    (role) => role.key === roleKey
  );
  if (currentRoleObject) return currentRoleObject.value;
  return "";
};

export const handleGetAdminRoleAccessFromRoleKey = function (roleKey: string) {
  const currentRoleObject = adminRolesAccessArr.find(
    (role) => role.roleKey === roleKey
  );
  if (currentRoleObject) return currentRoleObject.access;
  return [];
};

// Role access includes:
// - superAdmin: Everything
// - finance : Dashboard , Withdrawals, settlement
// - operations: Dashboard, Request, Merchant, Employers, Users, Partners,Products, purchases, deals,notification, settlement
// - author: Dashboard, Learning
