import React, { useContext, useEffect, useState } from "react";
import "./MerchantRequestsTable.css";
import { Table } from "react-bootstrap";
import DropdownInput from "../../../components/input-components/dropdown-input/DropdownInput";
import FEPagination from "../../../components/pagination/fe-pagination/FEPagination";
import isEmpty from "../../../validation/isEmpty";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import closeIcon from "../../../images/close-icon.svg";
import tickIcon from "../../../images/tick-icon.svg";
import { SearchContext } from "../../../components/contexts/SearchContext";
import pinIcon from "../../../images/pin-icon.svg";
import { ISingleMerchant } from "../../../Types";

interface IProps {
  allMerchantsRequests: ISingleMerchant[] | null;
  currentMerchantRequestPosts: ISingleMerchant[] | null;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setCurrentMerchantRequestPosts: (
    value: React.SetStateAction<ISingleMerchant[] | null>
  ) => void;
  setAllMerchantsRequests: React.Dispatch<
    React.SetStateAction<ISingleMerchant[] | null>
  >;
  merchantRequests: [] | ISingleMerchant[];
  rowsPerPage: number;
  indexOfFirstPost: number;
  indexOfLastPost: number;
  isLoading: boolean;
  isApproveRequestModalOpened: boolean;
  setIsApproveRequestModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isDeclineRequestModalOpened: boolean;
  setIsDeclineRequestModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMerchantRequest: (
    value: React.SetStateAction<ISingleMerchant | null>
  ) => void;
}

const MerchantRequestsTable: React.FC<IProps> = function ({
  allMerchantsRequests,
  currentMerchantRequestPosts,
  setCurrentPage,
  setCurrentMerchantRequestPosts,
  setAllMerchantsRequests,
  merchantRequests,
  currentPage,
  rowsPerPage,
  indexOfFirstPost,
  indexOfLastPost,
  isLoading,
  isApproveRequestModalOpened,
  isDeclineRequestModalOpened,
  setIsApproveRequestModalOpened,
  setIsDeclineRequestModalOpened,
  setSelectedMerchantRequest,
}) {
  // Functions and States
  const { searchedValue }: any = useContext(SearchContext);
  // States
  const [merchantRequestsDateFilter, setMerchantRequestsDateFilter] =
    useState("");
  // Functions
  const paginateForMerchantRequests = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  //   handle Filter Table
  const handleFilterTable = function () {
    if (!merchantRequests || isEmpty(merchantRequests)) return false;

    const merchantRequestsData = merchantRequests?.filter(
      (request) =>
        request.shopName.toLowerCase().includes(searchedValue.toLowerCase()) ||
        request.shopName.toLowerCase().includes(searchedValue.toLowerCase())
    );

    setAllMerchantsRequests(merchantRequestsData);
    // Update table state to merchant requests rows
    if (!allMerchantsRequests || !merchantRequestsData) return false;
    const currentRows = merchantRequestsData.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    setCurrentMerchantRequestPosts(currentRows);
  };

  useEffect(() => {
    handleFilterTable();
    setCurrentPage(1);
  }, [searchedValue]);

  return (
    <div className="merchant-requests-table-section--inner">
      {/* Merchant Requests Table Filter Wrapper */}
      <div className="requests--table-filter-option-wrapper">
        <div className="requests--table-filter-row-title">Filter:</div>
        {/* Filter by merchants' request application date dropdown */}
        <div className="requests--date-filter-form-group form-group">
          <DropdownInput
            className={"salary-advance-date-filter-dropdown-input"}
            id={"salary-advance-date-filter-input"}
            label={""}
            required={true}
            optionArray={[
              { key: "", value: "Date", default: "Default" },
              {
                key: "yesterday",
                value: "Yesterday",
              },
            ]}
            value={merchantRequestsDateFilter}
            onChange={(e) => setMerchantRequestsDateFilter(e.target.value)}
          />
        </div>
      </div>

      {/* All Merchant Requests Table Container */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "STORE NAME",
                "EMAIL",
                "PHONE NUMBER",
                "CAC DOCUMENT",
                "",
                "REASON",
                "",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentMerchantRequestPosts?.map((merchant, i) => (
              <tr key={i + 1}>
                {/* Merchant Name Column */}
                <td className="td-name">
                  <div className="td-name--wrapper">{merchant.shopName}</div>
                </td>

                {/*  Merchant Email Column */}
                <td className="td-email">
                  <div className="td-email--wrapper">{merchant.email}</div>
                </td>

                {/*  Merchant Phone number Column */}
                <td className="td-phone-number">
                  <div className="td-phone-number--wrapper">
                    {merchant.phoneNumber}
                  </div>
                </td>

                {/*  Cac Document Column */}
                <td className="td-cac-document">
                  <div className="td-cac-document--wrapper">
                    {merchant.cacDocument ? (
                      <a
                        href={merchant.cacDocument}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={pinIcon} alt="" />
                        CAC Document.
                        {merchant.cacDocument.substring(
                          merchant.cacDocument.length - 3
                        )}
                      </a>
                    ) : (
                      <div className="no-document-wrapper">No Document</div>
                    )}
                  </div>
                </td>

                {/* Decline Request Btn Column*/}
                <td className="td-decline-request">
                  {!merchant.isRejected ? (
                    <div className="td-decline-request--wrapper">
                      <button
                        onClick={() => {
                          setIsDeclineRequestModalOpened(true);
                          setSelectedMerchantRequest(merchant);
                        }}
                      >
                        <img src={closeIcon} alt="" />
                        Decline
                      </button>
                    </div>
                  ) : (
                    <div className="item-rejected-or-declined-wrapper">
                      Declined
                    </div>
                  )}
                </td>

                {/* Decline Request Btn Column*/}
                <td className="td-decline-request">
                  {!merchant.rejectReason ? (
                    <div className="item-rejected-or-declined-wrapper"></div>
                  ) : (
                    <div className="item-rejected-or-declined-wrapper">
                      {merchant.rejectReason}
                    </div>
                  )}
                </td>

                {/* Approve Request Btn Column*/}
                <td className="td-approve-request">
                  <div className="td-approve-request--wrapper">
                    <button
                      onClick={() => {
                        setIsApproveRequestModalOpened(true);
                        setSelectedMerchantRequest(merchant);
                      }}
                    >
                      <img src={tickIcon} alt="" />
                      Approve
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allMerchantsRequests && !isEmpty(currentMerchantRequestPosts) && (
        <FEPagination
          paginate={paginateForMerchantRequests}
          rowsPerPage={rowsPerPage}
          totalPosts={allMerchantsRequests.length}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default MerchantRequestsTable;
