// Interfaces
interface IProps {
  partnersCount: number;
}
function PartnersTop({ partnersCount }: IProps) {
  // Functions, States and Variables
  return (
    <div className="users--top-container">
      {/* Top Companies Box Container */}
      <div className="users--top--box-container top-companies-box-container">
        {/* Box Title Wrapper */}
        <div className="box-title-wrapper">TOTAL COUNT OF PARTNERS</div>
        {/* Box Value Wrapper */}
        <div className="box-value-wrapper">{partnersCount || 0}</div>
      </div>
    </div>
  );
}

export default PartnersTop;
