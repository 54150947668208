import React, { useEffect } from "react";
import styles from "./Main.module.css";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks";
import { logoutUser } from "../../../redux/actions/authActions";
import useInactivityLogout from "../../../hooks/useInactivityLogout";

// Interfaces
interface IProps {
  children: React.ReactNode;
}

function Main({ children }: IProps) {
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Functions
  // handle Log Out User
  const handleLogOutUser = function () {
    dispatch(logoutUser(navigate));
  };

  useInactivityLogout({
    inactivityExpireTime: 12000,
    onExpired: handleLogOutUser,
  });

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    // Check if User exists || is logged in
    if (!token) {
      window.location.href = "/";
    }
  }, []);

  return <section className={`${styles.app_main_section}`}>{children}</section>;
}

export default Main;
