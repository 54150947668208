import {
  GET_COURSE_VOUCHER_AND_PURCHASE_COUNT,
  GET_TOTAL_AMOUNT_DISBURSED,
} from "../Constants";

const initialState = {
  totalAmountDisbursed: { onDemand: 0, salaryAdvance: 0 },
  courseVoucherPurchaseCount: {
    vouchers: 0,
    courses: 0,
    purchase: 0,
    purchaseAmount: 0,
    merchant: 0,
    deal: 0,
    partner: 0,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state = initialState,
  action: { type?: string; payload?: any }
) {
  switch (action.type) {
    case GET_TOTAL_AMOUNT_DISBURSED:
      return {
        ...state,
        totalAmountDisbursed: action.payload,
      };
    case GET_COURSE_VOUCHER_AND_PURCHASE_COUNT:
      return {
        ...state,
        courseVoucherPurchaseCount: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
