import axios from "axios";
import {
  GET_COURSE_VOUCHER_AND_PURCHASE_COUNT,
  GET_TOTAL_AMOUNT_DISBURSED,
} from "../Constants";
import { api } from "../../api/config";
import { SetErrorHandlerType } from "../../Types";
import { handleAPIError } from "../../utils/handleAPIError";

const url = `${api}/api/v1/admin`;

const token: any = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get Total Amount Disbursed
export const getTotalAmountDisbursed =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/dashboard/loan-disbursed`)
      .then((res) => {
        dispatch({
          type: GET_TOTAL_AMOUNT_DISBURSED,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        handleAPIError(err, dispatch, setErrorHandlerObj);
      })
      .finally(() => setIsLoading(false));
  };

// Get Course Voucher and Purchase Count
export const getCourseVoucherPurchaseCount =
  (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorHandlerObj: SetErrorHandlerType
  ) =>
  (dispatch: any) => {
    setIsLoading(true);
    axios
      .get(`${url}/dashboard/course-voucher-and-purchase-count`)
      .then((res) => {
        dispatch({
          type: GET_COURSE_VOUCHER_AND_PURCHASE_COUNT,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        handleAPIError(err, dispatch, setErrorHandlerObj);
      })
      .finally(() => setIsLoading(false));
  };
