import React, { ChangeEvent, MouseEvent, useState } from "react";
import "../ProductDealSetup.css";
import TextInput from "../../../../components/input-components/text-input/TextInput";
import {
  IProductDeal,
  IProductDealFormInputState,
  SetErrorHandlerType,
} from "../../../../Types";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import PrimaryButton from "../../../../components/buttons/primary-button/PrimaryButton";
import axios from "axios";
import { api } from "../../../../api/config";

// Interfaces
interface IProps {
  pageType: string;
  currentDealObj: IProductDeal | null;
  dealFormInputState: IProductDealFormInputState;
  isLoading: boolean;
  setDealFormInputState: React.Dispatch<
    React.SetStateAction<IProductDealFormInputState>
  >;
  setErrorHandlerObj: SetErrorHandlerType;
  handleCreateOrEditDeal: (e: MouseEvent<HTMLButtonElement>) => void;
}

function DealsInfo({
  pageType,
  currentDealObj,
  dealFormInputState,
  isLoading,
  setDealFormInputState,
  setErrorHandlerObj,
  handleCreateOrEditDeal,
}: IProps) {
  // Functions, States and Variables
  // States
  const [isFileUploadLoading, setIsFileUploadLoading] = useState(false);
  // Functions
  //   Functions
  const handleUpdateDealsFormInputStates = function (
    fieldName: string,
    fieldValue: any
  ) {
    const updatedFormInputStates: IProductDealFormInputState = {
      ...dealFormInputState,
      [fieldName]: fieldValue,
    };
    setDealFormInputState(updatedFormInputStates);
  };

  // handle Upload Product Img
  const handleUploadProductImage = async function (
    e: ChangeEvent<HTMLInputElement>
  ) {
    setErrorHandlerObj({ hasError: false, message: "" });
    if (e.target.files && e.target.files[0].size <= 512000) {
      // handle Upload Variant Img
      const token = localStorage.getItem("jwtToken") ?? "";
      axios.defaults.headers.common["authorization"] = token;

      try {
        const selectedFile = e.target.files[0];
        const formData = new FormData();
        formData.append("file", selectedFile);

        setIsFileUploadLoading(true);
        const { data } = await axios.post(`${api}/api/v1/upload`, formData);

        if (data) handleUpdateDealsFormInputStates("productImage", data);

        setIsFileUploadLoading(false);
      } catch (ex) {
        setIsFileUploadLoading(false);

        setErrorHandlerObj({
          hasError: true,
          message: "Upload failed!",
        });
      }
    } else {
      setErrorHandlerObj({
        hasError: true,
        message: "File size exceeds 500kb!",
      });
    }
  };

  return (
    <div className="deals-container">
      <section className="deals-form-container">
        <form>
          <div className="form-header-wrapper">
            {pageType === "create" ? "Create a new" : "Edit current"} product
            deal
          </div>

          <div className="form-group-wrapper">
            {/* Deal Image form group */}
            <div className="product-deals-image-form-group file-form-group">
              <div className="file-form-group--inner">
                <div className="file-input-wrapper">
                  <div className="file-input-title">
                    {!dealFormInputState.productImage ? "Upload image" : ""}
                  </div>
                  {isFileUploadLoading && (
                    <div className="upload-loading-spinner-wrapper"></div>
                  )}
                  {!dealFormInputState.productImage && (
                    <input
                      type="file"
                      id={"deal-img-file-input"}
                      onChange={(e) => {
                        handleUploadProductImage(e);
                      }}
                      required
                      accept="image/*"
                    />
                  )}
                </div>

                {dealFormInputState.productImage && (
                  <div>
                    <img
                      className="product-img-preview"
                      src={dealFormInputState.productImage}
                      alt=""
                    />
                  </div>
                )}
              </div>

              <div className="product-deals-image-form-group__right">
                {dealFormInputState.productImage && (
                  <div className="edit-product-img-input">
                    <span>Edit image</span>
                    <input
                      type="file"
                      id={"deal-img-file-2-input"}
                      onChange={(e) => {
                        handleUploadProductImage(e);
                      }}
                      accept="image/*"
                    />
                  </div>
                )}

                <div className="image-dimension-spec-wrapper">
                  Dimension(W x H): 216 x 100
                </div>
              </div>
            </div>

            {/* Product Name group */}
            <div className="form-group">
              <TextInput
                type={"text"}
                id={"deals-product-name-input"}
                className={"deals-create-edit-input"}
                value={dealFormInputState.productName}
                onChange={(e) => {
                  handleUpdateDealsFormInputStates(
                    "productName",
                    e.target.value
                  );
                }}
                required={true}
                label={"Product Name"}
              />
            </div>

            {/* Merchant Name group */}
            <div className="form-group">
              <TextInput
                type={"text"}
                id={"deals-merchant-name-input"}
                className={"deals-create-edit-input"}
                value={dealFormInputState.merchantName}
                onChange={(e) => {
                  handleUpdateDealsFormInputStates(
                    "merchantName",
                    e.target.value
                  );
                }}
                required={true}
                label={"Merchant Name"}
              />
            </div>

            {/* Merchant Address group */}
            <div className="form-group">
              <TextInput
                type={"text"}
                id={"deals-merchant-address-input"}
                className={"deals-create-edit-input"}
                value={dealFormInputState.merchantAddress}
                onChange={(e) => {
                  handleUpdateDealsFormInputStates(
                    "merchantAddress",
                    e.target.value
                  );
                }}
                required={true}
                label={"Merchant Address"}
              />
            </div>

            {/* Category group */}
            {/* {pageType === "create" && (
              <div className="form-group">
                <DropdownInput
                  id={"deals-category-input"}
                  className={"deals-create-edit-input"}
                  label={"Category"}
                  required={true}
                  optionArray={categoryOptionsArr}
                  value={dealFormInputState.categoryId}
                  onChange={(e) => {
                    handleUpdateDealsFormInputStates(
                      "categoryId",
                      e.target.value
                    );
                  }}
                />
              </div>
            )} */}

            {/* Initial Amount form group */}
            <div className="form-group">
              <TextInput
                type={"number"}
                id="deals-inital-amount-input"
                className={"deals-create-edit-input"}
                value={dealFormInputState.actualCost}
                onChange={(e) => {
                  handleUpdateDealsFormInputStates(
                    "actualCost",
                    e.target.value
                  );
                }}
                required={true}
                label={"Initial Price"}
              />
            </div>

            {/* Amount form group */}
            <div className="form-group">
              <TextInput
                type={"number"}
                id="deals-deal-amount-input"
                className={"deals-create-edit-input"}
                value={dealFormInputState.dealCost}
                onChange={(e) => {
                  handleUpdateDealsFormInputStates("dealCost", e.target.value);
                }}
                required={true}
                label={"Deal Amount"}
              />
            </div>

            {/* Product Quantity form group */}
            <div className="form-group">
              <TextInput
                type={"number"}
                id="deals-quantity-input"
                className={"deals-create-edit-input"}
                value={dealFormInputState.quantity}
                onChange={(e) => {
                  handleUpdateDealsFormInputStates("quantity", e.target.value);
                }}
                required={true}
                label={"Product Quantity"}
              />
            </div>

            {/* Product Description form group */}
            <div className="form-group">
              <label htmlFor="product-descrp-textarea-input">
                Product Description
              </label>
              <textarea
                id={"product-descrp-textarea-input"}
                className={"product-deal-descrp-textarea-input"}
                placeholder={""}
                value={dealFormInputState.productDescription}
                onChange={(e) =>
                  handleUpdateDealsFormInputStates(
                    "productDescription",
                    e.target.value
                  )
                }
                required={true}
              />
            </div>

            {/* Expiry Date form group */}
            <div className="expiry-date-form-group form-group">
              <TextInput
                type={"datetime-local"}
                id="deals-expiry-date-input"
                className={"deals-create-edit-input"}
                value={dealFormInputState.expiryDate}
                onChange={(e) => {
                  handleUpdateDealsFormInputStates(
                    "expiryDate",
                    e.target.value
                  );
                }}
                required={true}
                label={"Expiry Date"}
              />
            </div>

            <div className="deal-form-primary-btn-container primary-button-container">
              {isLoading && <LoadingSpinner />}
              <PrimaryButton
                className="deal-setup-form--action-btn"
                placeholder="Submit"
                onClick={(e) => handleCreateOrEditDeal(e)}
              />
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}

export default DealsInfo;
