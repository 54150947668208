import React, { useEffect, useState } from "react";
// import percentIncreaseIcon from "../../../images/percent-increase-icon.svg";
// import percentDecreaseIcon from "../../../images/percent-decrease-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getCourseVoucherPurchaseCount,
  getTotalAmountDisbursed,
} from "../../../redux/actions/dashboardActions";
import { ISingleCompany, SetErrorHandlerType } from "../../../Types";
import {
  getAllCompanies,
  getAllStaffCount,
} from "../../../redux/actions/companyActions";

// Interfaces
interface IProps {
  setErrorHandlerObj: SetErrorHandlerType;
}

interface ITotalDisbursedAmount {
  onDemand: number;
  salaryAdvance: number;
}

interface ICourseVoucherPurchaseCount {
  vouchers: number;
  courses: number;
  purchase: number;
  purchaseAmount: number;
  merchant: number;
  deal: number;
  partner: number;
}
function DashboardRowOne({ setErrorHandlerObj }: IProps) {
  // Function, states and variables
  const dispatch = useAppDispatch();
  const disbursedAmount: ITotalDisbursedAmount = useAppSelector(
    (state) => state.dashboard.totalAmountDisbursed
  );
  const {
    courseVoucherPurchaseCount,
  }: { courseVoucherPurchaseCount: ICourseVoucherPurchaseCount } =
    useAppSelector((state) => state.dashboard);
  const companies: ISingleCompany[] | [] = useAppSelector(
    (state) => state.companies.companies
  );
  const staffCount = useAppSelector((state) => state.companies.totalStaffCount);
  // States
  const [totalAmountDisbursed, setTotalAmountDisbursed] = useState(0);
  const [totalOnDemandAmount, setTotalOnDemandAmount] = useState(0);
  const [totalSalaryAdvanceAmount, setTotalSalaryAdvanceAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // UseEffects
  useEffect(() => {
    dispatch(getTotalAmountDisbursed(setIsLoading, setErrorHandlerObj));
    dispatch(getCourseVoucherPurchaseCount(setIsLoading, setErrorHandlerObj));
    dispatch(getAllCompanies(setIsLoading, setErrorHandlerObj));
    dispatch(getAllStaffCount(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    setTotalOnDemandAmount(disbursedAmount.onDemand);
    setTotalSalaryAdvanceAmount(disbursedAmount.salaryAdvance);
    setTotalAmountDisbursed(
      disbursedAmount.salaryAdvance + disbursedAmount.onDemand
    );
  }, [disbursedAmount]);

  return (
    <div className="dashboard-row--one-section--inner">
      {/* Box Container && REVENUE */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">
          REVENUE
        </div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            &#8358;{totalAmountDisbursed?.toLocaleString()}
          </div>

          {/* percent change wrapper */}
          {/* <div
            className={`row--one-percent-change-wrapper ${
              amountTransactedPercentChange < 0
                ? "percent-decrease-wrapper"
                : "percent-increase-wrapper"
            }`}
          >
            {amountTransactedPercentChange.toString()}%{" "}
            <img
              src={
                amountTransactedPercentChange < 0
                  ? percentDecreaseIcon
                  : percentIncreaseIcon
              }
              alt=""
            />
          </div> */}
        </div>
      </div>

      {/* Box Container && BNPL (TAS) */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">ON DEMAND</div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            &#8358;{totalOnDemandAmount?.toLocaleString()}
          </div>
        </div>
      </div>

      {/* Box Container && SALARY ADVANCE */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">SALARY ADVANCE</div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            &#8358;{totalSalaryAdvanceAmount?.toLocaleString()}
          </div>
        </div>
      </div>

      {/* Box Container && PURCHASES AMOUNT */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">
          TOTAL AMOUNT OF PURCHASES
        </div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            &#8358;{courseVoucherPurchaseCount.purchaseAmount?.toLocaleString()}
          </div>
        </div>
      </div>

      {/* Box Container && PURCHASES COUNT */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">
          TOTAL NUMBER OF PURCHASES
        </div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            {courseVoucherPurchaseCount.purchase?.toLocaleString()}
          </div>
        </div>
      </div>

      {/* Box Container && VOUCHERS COUNT */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">
          TOTAL NUMBER OF VOUCHERS
        </div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            {courseVoucherPurchaseCount.vouchers?.toLocaleString()}
          </div>
        </div>
      </div>
      {/* Box Container && PRODUCT DEALS COUNT */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">
          TOTAL NUMBER OF PRODUCT DEALS
        </div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            {courseVoucherPurchaseCount.deal?.toLocaleString() || 0}
          </div>
        </div>
      </div>

      {/* Box Container && COURSES COUNT  */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">
          TOTAL NUMBER OF COURSES
        </div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            {courseVoucherPurchaseCount.courses?.toLocaleString()}
          </div>
        </div>
      </div>

      {/* Box Container && COMPANIES COUNT  */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">TOP COMPANIES</div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            {(process.env.REACT_APP_API_URL != 'https://backend.test.credley.africa') ? companies?.length?.toLocaleString() || 0: 22}
          </div>
        </div>
      </div>

      {/* Box Container && Employees COUNT  */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">
          TOTAL AMOUNT OF EMPLOYEES
        </div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            {(process.env.REACT_APP_API_URL != 'https://backend.test.credley.africa') ? staffCount?.toLocaleString() || 0 : 530}
          </div>
        </div>
      </div>

      {/* Box Container && MERCHANTS COUNT  */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">
          TOTAL AMOUNT OF MERCHANTS
        </div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            {courseVoucherPurchaseCount.merchant?.toLocaleString() || 0}
          </div>
        </div>
      </div>

      {/* Box Container && PARTNERS COUNT  */}
      <div className="dashboard-row-one-box-container">
        <div className="dashboard-box-title-wrapper">
          TOTAL AMOUNT OF PARTNERS
        </div>

        {/* Bottom Wrapper */}
        <div className="dashboard-row-one-box--bottom-wrapper">
          <div className="dahboard-box-value-wrapper">
            {courseVoucherPurchaseCount.partner?.toLocaleString() || 0}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardRowOne;
