import { useEffect, useState } from "react";
import "./Products.css";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import ErrorHandler from "../../components/error/ErrorHandler";
import SubstituteLoadingSpinner from "../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import SuccessHandler from "../../components/success/SuccessHandler";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAllCategories } from "../../redux/actions/categoryActions";
import { getAllProducts } from "../../redux/actions/productActions";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import { ISingleCategory, IVariants } from "../requests/Requests";
import AllProductsTable from "./all-products-table/AllProductsTable";
import CreateEditProductActionModal from "./modals/CreateEditProductActionModal";
import DeleteBrandModal from "./modals/DeleteBrandModal";
import ProductCategoriesTable from "./product-categories-table/ProductCategoriesTable";
import VouchersTable from "./vouchers-table/VouchersTable";
import ProductsHeaderSection from "./comps/products-header-section/ProductsHeaderSection";
import ProductsNav from "./comps/products-nav/ProductsNav";
import SelectedVouchersTable from "./selected-vouchers-table/SelectedVouchersTable";
import Main from "../../components/layout/main-container/Main";
import { ISingleMerchant } from "../../Types";

export interface ICategories {
  _id: string;
  name: string;
  imageUrl: string;
  createdAt: string;
}

export interface IVouchers {
  id: number;
  business_name: string;
  image: null;
  currency_code: string;
  source: string;
  redeem_instructions_html: string;
  maximum_value: string;
  minimum_value: string;
}

export interface IProducts {
  category: ISingleCategory;
  description: string;
  image: string;
  isActive: boolean;
  isApproved: boolean;
  merchant: ISingleMerchant;
  name: string;
  pictures: string[];
  price: number;
  quantity: number;
  size: string;
  _id: string;
  createdAt: string;
  variants?: IVariants[] | [];
  isHotDeal?: boolean;
}

export interface IBrands {
  isActive: boolean;
  _id: string;
  name: string;
  createdAt?: string;
}

function Products() {
  // Functions, States and Variables
  const categories: ICategories[] | [] = useAppSelector(
    (state) => state.categories.categories
  );
  const products: IProducts[] | [] = useAppSelector(
    (state) => state.products.products
  );
  const brands: IBrands[] | [] = useAppSelector((state) => state.brands.brands);
  const dispatch = useAppDispatch();
  // States
  const [pageContent, setPageContent] = useState("categories");
  const [allCategories, setAllCategories] = useState<ICategories[] | null>(
    null
  );
  const [allProducts, setAllProducts] = useState<IProducts[] | null>(null);
  const [allBrands, setAllBrands] = useState<IBrands[] | null>(null);
  // Modals
  const [
    isCreateEditProductActionModalOpened,
    setIsCreateEditProductActionModalOpened,
  ] = useState(false);
  const [modalActionType, setModalActionType] = useState("create");
  const [isDeleteBrandModalOpened, setIsDeleteBrandModalOpened] =
    useState(false);

  // For Categories
  const [selectedCategoryForEdit, setSelectedCategoryForEdit] =
    useState<ICategories | null>(null);
  const [currentPageForCategories, setCurrentPageForCategories] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [currentCategoriesPosts, setCurrentCategoriesPosts] = useState<
    ICategories[] | null
  >(null);
  const indexOfLastPostForCategories = currentPageForCategories * rowsPerPage;
  const indexOfFirstPostForCategories =
    indexOfLastPostForCategories - rowsPerPage;
  // For Products
  const [currentPageForProducts, setCurrentPageForProducts] = useState(1);
  const [currentProductsPosts, setCurrentProductsPosts] = useState<
    IProducts[] | null
  >(null);
  const indexOfLastPostForProducts = currentPageForProducts * rowsPerPage;
  const indexOfFirstPostForProducts = indexOfLastPostForProducts - rowsPerPage;
  // For Brands
  const [selectedBrandForDeleting, setSelectedBrandForDeleting] =
    useState<IBrands | null>(null);
  const [currentPageForBrands, setCurrentPageForBrands] = useState(1);
  const [currentBrandsPosts, setCurrentBrandsPosts] = useState<
    IBrands[] | null
  >(null);
  const indexOfLastPostForBrands = currentPageForBrands * rowsPerPage;
  const indexOfFirstPostForBrands = indexOfLastPostForBrands - rowsPerPage;

  const [isTableActionLoading, setIsTableActionLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      dispatch(getAllProducts(setIsLoading, setErrorHandlerObj));
      dispatch(getAllCategories(setIsLoading, setErrorHandlerObj));
    }
  }, [successHandlerObj]);

  useEffect(() => {
    dispatch(getAllProducts(setIsLoading, setErrorHandlerObj));
    dispatch(getAllCategories(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    setAllCategories(categories);
  }, [categories]);

  useEffect(() => {
    setAllProducts(products);
  }, [products]);

  useEffect(() => {
    setAllBrands(brands);
  }, [brands]);

  // For Categories
  useEffect(() => {
    if (allCategories && pageContent === "categories") {
      const currentRows = allCategories?.slice(
        indexOfFirstPostForCategories,
        indexOfLastPostForCategories
      );

      setCurrentCategoriesPosts(currentRows);
    }
  }, [allCategories, currentPageForCategories, pageContent]);

  // For Products
  useEffect(() => {
    if (allProducts && pageContent === "products") {
      const currentRows = allProducts?.slice(
        indexOfFirstPostForProducts,
        indexOfLastPostForProducts
      );

      setCurrentProductsPosts(currentRows);
    }
  }, [allProducts, currentPageForProducts, pageContent]);

  // For Brands
  useEffect(() => {
    if (allBrands && pageContent === "brands") {
      const currentRows = allBrands?.slice(
        indexOfFirstPostForBrands,
        indexOfLastPostForBrands
      );

      setCurrentBrandsPosts(currentRows);
    }
  }, [allProducts, currentPageForBrands, pageContent]);

  return (
    <div
      className={`app-page-container  ${
        isCreateEditProductActionModalOpened || isDeleteBrandModalOpened
          ? "overflow-hidden"
          : ""
      }`}
    >
      <Top />
      <Sidebar activeName={"Products"} />

      {/* Gray Overlay */}
      <div
        onClick={() => {
          setIsCreateEditProductActionModalOpened(false);
          setIsDeleteBrandModalOpened(false);
        }}
        className={`gray-overlay ${
          isCreateEditProductActionModalOpened || isDeleteBrandModalOpened
            ? ""
            : "none"
        }`}
      ></div>

      {/* Create / Edit Modal For Both Categories And Brands Dynamically */}
      <CreateEditProductActionModal
        isCreateEditProductActionModalOpened={
          isCreateEditProductActionModalOpened
        }
        setIsCreateEditProductActionModalOpened={
          setIsCreateEditProductActionModalOpened
        }
        selectedCategoryForEdit={selectedCategoryForEdit}
        modalActionType={modalActionType}
        pageContent={pageContent}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
      />

      {/* Delete Brand Modal  */}
      <DeleteBrandModal
        isDeleteBrandModalOpened={isDeleteBrandModalOpened}
        setIsDeleteBrandModalOpened={setIsDeleteBrandModalOpened}
        selectedBrandForDeleting={selectedBrandForDeleting}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
      />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />
        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app--actions-handler-wrapper"
        />

        <section className="app-main-section--inner">
          {/* Header Section / Row One Section */}
          <ProductsHeaderSection products={products} categories={categories} />

          {/* Table Container Section / Row Two Section */}
          <section className="products--row-two-section">
            {/* Products Nav Section */}
            <ProductsNav
              pageContent={pageContent}
              setPageContent={setPageContent}
            />

            <section className={`products-table-section`}>
              {/* If Table Action is Loading */}
              {isTableActionLoading && (
                <div className="table-action-loading-spinner-wrapper">
                  {<SubstituteLoadingSpinner />}
                </div>
              )}
              {/* Product Categories Table Section */}
              {pageContent === "categories" && (
                <ProductCategoriesTable
                  categories={categories}
                  allCategories={allCategories}
                  setAllCategories={setAllCategories}
                  currentCategoriesPosts={currentCategoriesPosts}
                  setCurrentCategoriesPosts={setCurrentCategoriesPosts}
                  currentPage={currentPageForCategories}
                  setCurrentPage={setCurrentPageForCategories}
                  rowsPerPage={rowsPerPage}
                  indexOfFirstPost={indexOfFirstPostForCategories}
                  indexOfLastPost={indexOfLastPostForCategories}
                  isLoading={isLoading}
                  isCreateEditProductActionModalOpened={
                    isCreateEditProductActionModalOpened
                  }
                  setIsCreateEditProductActionModalOpened={
                    setIsCreateEditProductActionModalOpened
                  }
                  setModalActionType={setModalActionType}
                  setSelectedCategoryForEdit={setSelectedCategoryForEdit}
                />
              )}

              {/* All Product Table Section */}
              {pageContent === "products" && (
                <AllProductsTable
                  products={products}
                  allProducts={allProducts}
                  setAllProducts={setAllProducts}
                  currentProductsPosts={currentProductsPosts}
                  setCurrentProductsPosts={setCurrentProductsPosts}
                  currentPage={currentPageForProducts}
                  setCurrentPage={setCurrentPageForProducts}
                  rowsPerPage={rowsPerPage}
                  indexOfFirstPost={indexOfFirstPostForProducts}
                  indexOfLastPost={indexOfLastPostForProducts}
                  isLoading={isLoading}
                  setIsTableActionLoading={setIsTableActionLoading}
                  setErrorHandlerObj={setErrorHandlerObj}
                  setSuccessHandlerObj={setSuccessHandlerObj}
                />
              )}

              {/* Brands Table Section */}
              {/* {pageContent === "brands" && (
                <BrandsTable
                  brands={brands}
                  allBrands={allBrands}
                  setAllBrands={setAllBrands}
                  currentBrandsPosts={currentBrandsPosts}
                  setCurrentBrandsPosts={setCurrentBrandsPosts}
                  currentPage={currentPageForBrands}
                  setCurrentPage={setCurrentPageForBrands}
                  rowsPerPage={rowsPerPage}
                  indexOfFirstPost={indexOfFirstPostForBrands}
                  indexOfLastPost={indexOfLastPostForBrands}
                  isLoading={isLoading}
                  isCreateEditProductActionModalOpened={
                    isCreateEditProductActionModalOpened
                  }
                  setIsCreateEditProductActionModalOpened={
                    setIsCreateEditProductActionModalOpened
                  }
                  isDeleteBrandModalOpened={isDeleteBrandModalOpened}
                  setIsDeleteBrandModalOpened={setIsDeleteBrandModalOpened}
                  setSelectedBrandForDeleting={setSelectedBrandForDeleting}
                />
              )} */}

              {/* Vouchers Table Section */}
              {pageContent === "vouchers" && (
                <VouchersTable
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  setIsTableActionLoading={setIsTableActionLoading}
                  setErrorHandlerObj={setErrorHandlerObj}
                  setSuccessHandlerObj={setSuccessHandlerObj}
                />
              )}

              {/* Selected Vouchers Table Section */}
              {pageContent === "selected-vouchers" && (
                <SelectedVouchersTable
                  rowsPerPage={rowsPerPage}
                  isLoading={isLoading}
                  successHandlerObj={successHandlerObj}
                  setIsLoading={setIsLoading}
                  setIsTableActionLoading={setIsTableActionLoading}
                  setErrorHandlerObj={setErrorHandlerObj}
                  setSuccessHandlerObj={setSuccessHandlerObj}
                />
              )}
            </section>
          </section>
        </section>
      </Main>
    </div>
  );
}

export default Products;
