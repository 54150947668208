import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Top from "../../components/app-top/Top";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import Main from "../../components/layout/main-container/Main";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useDisplayMessage from "../../hooks/useDisplayMessage";
import PartnersTop from "./comps/partners-top/PartnersTop";
import {
  ISearchContextType,
  SearchContext,
} from "../../components/contexts/SearchContext";
import { IAPIResponseData, IPartner } from "../../Types";
import {
  getAllPartnerCount,
  getAllPartners,
} from "../../redux/actions/partnersAction";
import isEmpty from "../../validation/isEmpty";
import BEPagination from "../../components/pagination/be-pagination/BEPagination";
import PartnersTable from "./comps/partners-table/PartnersTable";

function Partners() {
  // Functions, States and Variables
  const dispatch = useAppDispatch();
  const partnersObject: IAPIResponseData = useAppSelector(
    (state) => state.partners.partners
  );
  const partnersCount = useAppSelector((state) => state.partners.partnerCount);
  const { searchedValue }: ISearchContextType | any = useContext(SearchContext);
  // States
  const [allPartners, setAllPartners] = useState<IPartner[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  useState(false);
  //   Hooks
  // Handles Display the error and success message and scrolls to top
  const {
    errorHandlerObj,
    setErrorHandlerObj,
    successHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  // Pagination
  const [pageNumberIndex, setPageNumberIndex] = useState(1);

  //   handle Filter Table
  const handleFilterTable = function () {
    const partners: IPartner[] | [] = partnersObject.data;
    let allNewPartners = partners?.filter(
      (partner) =>
        partner?.name.toLowerCase().includes(searchedValue.toLowerCase()) ||
        partner?.email.toLowerCase().includes(searchedValue.toLowerCase())
    );
    setAllPartners(allNewPartners);
  };

  const handleFetchPartners = function () {
    dispatch(getAllPartners(pageNumberIndex, setIsLoading, setErrorHandlerObj));
  };

  //   UseEffects
  useEffect(() => {
    dispatch(getAllPartnerCount(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    // Fetch partners based on selected paginated page
    handleFetchPartners();
  }, [pageNumberIndex]);

  useEffect(() => {
    if (partnersObject) handleFilterTable();
  }, [partnersObject, searchedValue]);

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Partners"} />

      <Main>
        {/* Error handler component */}
        <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="app--actions-handler-wrapper"
        />
        {/* Success handler component */}
        <SuccessHandler
          successHandlerObj={successHandlerObj}
          className="app--actions-handler-wrapper"
        />
        <section className={`app-main-section--inner`}>
          {/* Partners top container */}
          <PartnersTop partnersCount={partnersCount} />

          {/* Partners Table  */}
          <section className={`audit-trail-table-wrapper`}>
            <PartnersTable currentPosts={allPartners} isLoading={isLoading} />

            {/* Pagination */}
            {!isEmpty(allPartners) && !(isLoading && isEmpty(allPartners)) && (
              <BEPagination
                currentPage={partnersObject?.currentPage || 1}
                lastPage={partnersObject.totalPages}
                currentPosts={allPartners}
                setPageNumberIndex={setPageNumberIndex}
              />
            )}
          </section>
        </section>
      </Main>
    </div>
  );
}

export default Partners;
